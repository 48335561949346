import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import MasterCard from "../assets/images/mastercard-pay.png";
import VisaCard from "../assets/images/visacard-pay.png";
import GPay from "../assets/images/GPay-pay.png";
const MainSlider = ({ banners }) => {
  const settings = {
    dots: true,
    // fade: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="mainBanner">
      <Slider {...settings}>
        {banners &&
          banners.length > 0 &&
          banners.map((b, index) => {
            return (
              <div key={index} className="position-relative" href="/sportsbook">
                <img src={b.link} alt="First Slide" />
              </div>
            );
          })}

        {/* <a className="position-relative" href="/sportsbook">
          <img src="./images/banner2.png" alt="Second Slide" />
        </a>
        <a className="position-relative" href="/sportsbook">
          <img src="./images/banner3.png" alt="Thired Slide" />
        </a> */}
      </Slider>

      {/* <Slider {...settings}>
        <div className="position-relative">
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner_info">
                    <h2>Casino made fast & easy</h2>
                    <ul className="payment">
                      <li>
                        <img src={MasterCard} alt="Master Card" />
                      </li>
                      <li>
                        <img src={VisaCard} alt="Visa Card" />
                      </li>
                      <li>
                        <img src={GPay} alt="GPay" />
                      </li>
                    </ul>
                    <Button variant="primary" className="button-primary">
                      PLAY NOW
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-relative">
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner_info">
                    <h2>Casino made fast & easy</h2>
                    <ul className="payment">
                      <li>
                        <img src={MasterCard} alt="Master Card" />
                      </li>
                      <li>
                        <img src={VisaCard} alt="Visa Card" />
                      </li>
                      <li>
                        <img src={GPay} alt="GPay" />
                      </li>
                    </ul>
                    <Button variant="primary" className="button-primary">
                      PLAY NOW
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider> */}
    </div>
  );
};

export default MainSlider;
