import {
  SPORTS_DATA,
  ODDS_DATA,
  BET_SLIP,
  BET_SLIP_CONTENT,
  SET_SESSION_DATA,
  SET_SESSION_ODDS,
  BET_MATCHES,
  USER_BETS,
  MATCH_BETS,
  BOOKMAKER_DATA,
} from "./type";
import { API_URLS } from "../../config/apiUrls";
import axios from "axios";
const sportsBaseUrl = process.env.REACT_APP_SPORTS_HOST;

// get all sports data (SOCKETS)
export const setSportsData = (data) => (dispatch) => {
  dispatch({
    type: SPORTS_DATA,
    payload: data,
  });
};

//get all match odds (SOCKETS)
export const setOddsData = (data) => (dispatch) => {
  dispatch({
    type: ODDS_DATA,
    payload: data,
  });
};

// handle betslip

export const showbetSlip = (isShow) => (dispatch) => {
  try {
    dispatch({
      type: BET_SLIP,
      payload: isShow,
    });
  } catch (err) {
    console.debug(err);
  }
};

// betslip Content

export const betSlipContent = (data) => (dispatch) => {
  try {
    dispatch({
      type: BET_SLIP_CONTENT,
      payload: data,
    });
  } catch (err) {
    console.debug(err);
  }
};

// bet place

export const placeBet = (payload) => (dispatch) => {
  try {
    // const result = axiosService.post(API_URLS.SPORTS.PLACE_BET, payload);
    const result = axios.post(
      `${sportsBaseUrl}${API_URLS.SPORTS.PLACE_BET}`,
      payload,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};

export const getBetMatches = (payload) => (dispatch) => {
  try {
    const result = axios.get(
      `${sportsBaseUrl}${API_URLS.SPORTS.GET_BET_MATCHES}?status=${payload.status}`,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );

    result.then((res) => {
      dispatch({
        type: BET_MATCHES,
        payload: res?.data?.data?.matches,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};

export const getMatchBets = (payload) => (dispatch) => {
  try {
    const result = axios.get(
      `${sportsBaseUrl}${API_URLS.SPORTS.GET_USER_BETS}?skip=${payload.skip}&pagesize=${payload.pageSize}&matchId=${payload?.matchId}`,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );
    result.then((res) => {
      dispatch({
        type: MATCH_BETS,
        payload: res?.data?.data?.bets,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};

export const placeFancyBet = async (payload) => {
  try {
    const result = axios.post(
      `${sportsBaseUrl}${API_URLS.SPORTS.PLACE_FANCY_BET}`,
      payload,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};

// set session

export const setSession = (data) => (dispatch) => {
  try {
    dispatch({
      type: SET_SESSION_DATA,
      payload: data,
    });
  } catch (err) {
    console.debug(err);
  }
};
// set session odds

export const setSessionOdds = (data) => (dispatch) => {
  try {
    dispatch({
      type: SET_SESSION_ODDS,
      payload: data,
    });
  } catch (err) {
    console.debug(err);
  }
};

// get team exposure

export const getTeamExposureAction = (payload) => (dispatch) => {
  try {
    const result = axios.get(
      `${sportsBaseUrl}${API_URLS.SPORTS.GET_BET_MATCHES}?matchid=${payload}`,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );

    return result;
  } catch (err) {
    console.debug(err);
  }
};

export const getLiveTVUrl = async () => {
  try {
    const result = await axios.get(API_URLS.SPORTS.GET_LIVE_TV_URL, {
      "Access-Control-Allow-Origin": "*",
    });
    return result;
  } catch (err) {
    console.debug(err);
  }
};

export const setBookMakerData = (data) => (dispatch) => {
  dispatch({
    type: BOOKMAKER_DATA,
    payload: data,
  });
};

export const placeBookMakerBet = async (payload) => {
  try {
    const result = axios.post(
      `${sportsBaseUrl}${API_URLS.SPORTS.PLACE_BOOKMAKER_BET}`,
      payload,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};
