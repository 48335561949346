export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  GATEWAYS: {
    M_BKASH: "m_bkash",
    M_NAGAD: "m_nagad",
    M_BINANCE: "m_binance",
    M_UPAY: "m_upay",
    M_ROCKET: "m_rocket",
    M_BINANCE_TRON: "m_binance_tron",
    M_BINANCE_BSC: "m_binance_bsc",
    M_PAYTM: "m_paytm",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  TEST: {},
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },
});
