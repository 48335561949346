import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import TeenPatti from "../../../../assets/images/teenpattiplayer.jpg";
import GoaAndarBahar from "../../../../assets/images/Goas-Andar-Bahar.jpg";
import KingRace from "../../../../assets/images/king-race.jpg";
import WorliMatka from "../../../../assets/images/Worli-Matka.jpg";
import TeenPatti1 from "../../../../assets/images/teenpatti12.jpg";
import ClassicAB from "../../../../assets/images/classicandarbahar.jpg";
import Baccarat1 from "../../../../assets/images/baccaratnew.jpg";
import TeenPattiA from "../../../../assets/images/teenpattiagain.jpg";
import DragonTiger from "../../../../assets/images/dragon-tiger.jpg";
import Lucky7 from "../../../../assets/images/lucky7.jpg";
import AndarBaharnew from "../../../../assets/images/andar-bahar.jpg";
import TeenPattiFaceOff from "../../../../assets/images/teenplattifaceoff.jpg";
import Roulettenew from "../../../../assets/images/roulette.jpg";
import AkbarRomeo from "../../../../assets/images/akbar-romeo.jpg";
import Cards32 from "../../../../assets/images/32cards.jpg";
import Pokernew from "../../../../assets/images/Poker.jpg";
import MulfisTP from "../../../../assets/images/muflisteenpatti.jpg";

const Worldcasino = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="conatiner">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="sectionTitle">Slots</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="casino-images-section">
            <a
              href="/casino/worldcasino/TeenPatti3WC"
              className="casino-images"
            >
              <img src={TeenPatti} />
              <p>3 Player TeenPatti</p>
            </a>
            <a
              href="/casino/worldcasino/GoaAndarbaharWC"
              className="casino-images"
            >
              <img src={GoaAndarBahar} />
              <p>Goa's Andar Bahar</p>
            </a>
            <a href="/casino/worldcasino/kingraceWC" className="casino-images">
              <img src={KingRace} />
              <p>King Race</p>
            </a>
            <a
              href="/casino/worldcasino/worlimatkaWC"
              className="casino-images"
            >
              <img src={WorliMatka} />
              <p>Worli Matka </p>
            </a>
            <a
              href="/casino/worldcasino/teenpatti2020WC"
              className="casino-images"
            >
              <img src={TeenPatti1} />
              <p>Teen Patti 2020 </p>
            </a>
            <a href="/casino/worldcasino/classicABWC" className="casino-images">
              <img src={ClassicAB} />
              <p>Classic Andar Bahar</p>
            </a>
            <a href="/casino/worldcasino/BaccaratWC" className="casino-images">
              <img src={Baccarat1} />
              <p>Baccarat</p>
            </a>
            <a href="/casino/worldcasino/teenpattiWC" className="casino-images">
              <img src={TeenPattiA} />
              <p>Teen Patti</p>
            </a>
            <a
              href="/casino/worldcasino/dragonTigerWC"
              className="casino-images"
            >
              <img src={DragonTiger} />
              <p>Dragon Tiger</p>
            </a>
            <a href="/casino/worldcasino/lucky7WC" className="casino-images">
              <img src={Lucky7} />
              <p>Lucky 7</p>
            </a>
            <a
              href="/casino/worldcasino/AndarBaharWC"
              className="casino-images"
            >
              <img src={AndarBaharnew} />
              <p>Andar Bahar</p>
            </a>
            <a
              href="/casino/worldcasino/teenpattifaceOffWC"
              className="casino-images"
            >
              <img src={TeenPattiFaceOff} />
              <p>Teen Patti Face Off</p>
            </a>
            <a href="/casino/worldcasino/rouletteWC" className="casino-images">
              <img src={Roulettenew} />
              <p>Roulette</p>
            </a>
            <a
              href="/casino/worldcasino/AkbarRomeoWC"
              className="casino-images"
            >
              <img src={AkbarRomeo} />
              <p>Akbar Romeo</p>
            </a>
            <a href="/casino/worldcasino/card32WC" className="casino-images">
              <img src={Cards32} />
              <p>32 Cards</p>
            </a>
            <a href="/casino/worldcasino/PokerWC" className="casino-images">
              <img src={Pokernew} />
              <p>RNG Poker</p>
            </a>
            <a href="/casino/worldcasino/muflisTPWC" className="casino-images">
              <img src={MulfisTP} />
              <p>Muflis Teenpatti</p>
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Worldcasino;
