import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Navbar, Nav } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import logo from "../assets/svg/logo_new.png";
import { useSelector } from "react-redux";
import Fbicon from "../assets/images/head-icon/facebook.png";
import Twitter from "../assets/images/head-icon/twitter.png";
import Telegram from "../assets/images/head-icon/telegram.png";
import Instagram from "../assets/images/head-icon/instagram.png";
import Mobile from "../assets/images/head-icon/mobile.png";
import i18next, { t } from "i18next";
import MenuIcon from "../assets/svg/MenuIcon";

const Header = () => {
  const { isAuth } = useSelector((state) => state.auth);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);
  // useEffect(() => {
  //   const addGoogleTranslateScript = () => {
  //     const script = document.createElement("script");
  //     script.src =
  //       "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   };

  //   const googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement(
  //       {
  //         pageLanguage: "en",
  //         includedLanguages: "en,nl",
  //         layout: "google.translate.TranslateElement.InlineLayout.SIMPLE",
  //       },
  //       "google_translate_element"
  //     );
  //   };

  //   var userLang = navigator.language || navigator.userLanguage;
  //   // alert ("The language is: " + userLang);
  //   // setcookie('googtrans', '/en/pt');
  //   document.cookie = `googtrans=/en/${userLang};max-age=604800;`;

  //   if (window.google && window.google.translate) {
  //     googleTranslateElementInit();
  //   } else {
  //     window.googleTranslateElementInit = googleTranslateElementInit;
  //     addGoogleTranslateScript();
  //   }
  // }, []);
  return (
    <header className="header bfrlgn">
      <div className="top-head">
        <div className="tophead-left">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "#"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Fbicon} />
                <p>Facebook</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                // href={
                //   appDetails?.TWITTER != ""
                //     ? appDetails?.TWITTER
                //     : "#"
                // }
                // target={appDetails?.TWITTER != "" ? "_blank" : ""}
              >
                <img src={Twitter} />
                <p>Twitter</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} />
                <p>Instagram</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "#"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} />
                <p>Telegram Channel</p>
              </a>
            </li>

            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Mobile} />
                <p>Message Us</p>
              </a>
            </li>
          </ul>
        </div>
        <div className="tophead-right d-none">
          <div className="time-date">
            <span>May 26, 2023 at 1:57:22 PM</span>
          </div>
          <div className="language">
            <div className="language-option">
              <select
                className="language-select btn-outline-secondary beforelogin-language"
                value={window.localStorage.i18nextLng}
                onChange={handleLanguageChange}
              >
                <option value="en">English</option>
                <option value="arab">عربى</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center nav-head">
        <div className="d-flex align-items-center">
          <div className="leftbar_toggle d-xl-none">
            <Button
              variant="primary"
              onClick={() => setShowLeftbarToggle((s) => !s)}
            >
              <MenuIcon />
            </Button>
          </div>
          <div className="logo">
            <a href="/">
              {appDetails?.LOGO_URL && (
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              )}
            </a>
          </div>
          <Navbar expand="lg">
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/bet-by?bt-path=%2Fcricket-21">
                    Cricket
                  </Nav.Link>
                  <Nav.Link href="/bet-by">SportsBook</Nav.Link>

                  {/* <Nav.Link href="/casino/evolution">Vivo</Nav.Link> */}
                  <Nav.Link href="/casino/evolution">Live Casino</Nav.Link>
                  <Nav.Link href={isAuth ? "#" : "/sign-in"}>
                    Promotion
                  </Nav.Link>
                  <Nav.Link href="/casino/spribe/plinko">Plinko</Nav.Link>
                  <Nav.Link href="/casino/spribe/plinko">Slots</Nav.Link>
                  {/* <Nav.Link href="/casino/spribe/plinko">Worldcasino</Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="headerRight">
          {
            <ul>
              {/* <li>
                    <a target="_blank" href="https://t.me">
                      <TelegramIcon />
                    </a>
                  </li> */}
              {/* <li>
                <div className="google-translate-container gt-a">
                  <div id="google_translate_element" />
                  <div className="custom-google-translate-icon">Language</div>
                </div>
              </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
            </ul>
          }
          <Button
            variant=""
            onClick={() => {
              navigate("/sign-in");
            }}
            className="btn-login ms-2"
          >
            LOGIN
          </Button>
          <Button
            variant=""
            onClick={() => {
              navigate("/mobile-number");
            }}
            className="btn-register ms-2"
          >
            JOIN NOW
          </Button>
        </div>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
