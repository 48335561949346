import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import BSCPay from "../../../../assets/images/bsc.png";
import { Button } from "react-bootstrap";

const BSCConfirm = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main tron mt-4">
        <div className=" container">
          <div className="d-flex align-items-center justify-content-between">
            <h2 class="sectionTitle">
              <img src={BSCPay} /> Tether on BSC{" "}
            </h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div class="tron-section">
            <div className="tron-heading">
              <img src={BSCPay} />
            </div>
            <div className="tron-content">
              <p>
                <span>ONLY send USDT (Binance Smart Chain-BEP 20)</span>to this
                deposit address.
              </p>
              <p>
                <span>Do NOT use</span>BEP2, ERC20, OMNI, TRC20, or other
                network standards as you may lose your funds.{" "}
                <span>
                  ONLY make deposits through the Binance Smart Chain (BEP20)
                  network.
                </span>
              </p>
              <p>
                <span>The use of contract addresses is not allowed!</span>
              </p>
              <p>
                By clicking the "Confirm" button, you indicate that you agree to
                the above-mentioned risk.{" "}
              </p>

              <Button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate("/BSCQRcode")}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BSCConfirm;
