import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Facebook from "../../../../assets/images/facebook-512.svg";
import Instagram from "../../../../assets/images/Instagram.svg";

import { useSelector } from "react-redux";

const ContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);


  return (
    <div>
      <div>
        <HeaderAfterLogin></HeaderAfterLogin>
      </div>
      <main class="main page-content">
        <div class="container">
          <h3 class="page-heading">Contact Us</h3>
          <div class="contact-content">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              {/* <span className="notification-count">2</span> */}
              <img src={Whatsapp} alt="Whatsapp Icon" />
            </a>
            <a
              href={
                appDetails?.TELEGRAM != ""
                  ? appDetails?.TELEGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="Telegram Icon" />
            </a>

            <a
              href={
                appDetails?.INSTATGRAM != ""
                  ? appDetails?.INSTATGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
            >
              <img src={Instagram} alt="Instagram Icon" />
            </a>

            <a
              href={
                appDetails?.FACEBOOK != ""
                  ? appDetails?.FACEBOOK
                  : "javascript:void(0)"
              }
              target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
            >
              <img src={Facebook} alt="Facebook Icon" />
            </a>
          </div>
        </div>
      </main>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
};
export default ContactUs;
