import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { useSelector } from "react-redux";

const BeforeFairPlay = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>
        <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      </div>
      <main class="main page-content">
        <div class="container">
          <h3 class="page-heading">Fair Play</h3>
          <p>
            Takegambles treats all Players equally and respectfully. Takegambles
            Provide Fair Odds and Fair winnings. Fair competition, respect,
            friendship, team spirit, equality, sport without doping, respect for
            written and unwritten rules such as integrity, solidarity,
            tolerance, care, excellence and joy, are the building blocks of fair
            play that can be experienced and learnt both on and off the field.
          </p>
        </div>
      </main>
      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default BeforeFairPlay;
