import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import ProfileIcon from "../assets/svg/ProfileIcon";
import logo from "../assets/svg/logo_new.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { AiFillStar } from "react-icons/ai";
import {
  BsStarHalf,
  BsTelegram,
  BsTelephone,
  BsWhatsapp,
} from "react-icons/bs";
import { APP_CONST } from "../config/const";
import { socket } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import Loader from "../assets/images/games-tab/loader-new.gif";
import { useLocation } from "react-router-dom";

import Visa from "../assets/images/providers/upi/visa.svg";
import Tether from "../assets/images/providers/upi/tether.svg";
import Bitcoin from "../assets/images/providers/upi/bitcoin.svg";
import Skrill from "../assets/images/providers/upi/skrill.svg";
import Astropay from "../assets/images/providers/upi/astropay.png";
import Phonepay from "../assets/images/providers/upi/phonepa.png";
import Netbank from "../assets/images/providers/upi/netbank.png";
import Gpay from "../assets/images/providers/upi/gpay.png";
import Mastercard from "../assets/images/providers/upi/mastercard.svg";
import Paytm from "../assets/images/providers/upi/paytm.png";
import rupay from "../assets/images/providers/upi/rupay.png";
import neteller from "../assets/images/providers/upi/neteller.png";

import rightArrowSeeAll from "../assets/images/right-arrow-white.png";
import Noga from "../assets/images/icons/Noga.png";
import LoketKansspel from "../assets/images/icons/LoketKansspel.png";
import IBIA from "../assets/images/icons/IBIA.png";
import cgf from "../assets/images/icons/cgf-white-svg.png";

// import FreeBetBlackevolution from "../assets/images/icons/FreeBet-Black-evolution.png";

import { AiOutlineQuestionCircle } from "react-icons/ai";
import { AiOutlineMobile } from "react-icons/ai";

import i18next, { t } from "i18next";
import BitCoin from "../assets/images/providers/upi/BitCoin.png";
import Ethereum from "../assets/images/providers/upi/Ethereum.png";
import apecoin from "../assets/images/providers/upi/apecoin-ape-ape-logo.png";
import bitcoinCash from "../assets/images/providers/upi/bitcoin-cash-bch-logo.png";
import dogecoinDogeLogo from "../assets/images/providers/upi/dogecoinDogeLogo.png";
import litecoin from "../assets/images/providers/upi/litecoin-ltc-logo.png";
import matic from "../assets/images/providers/upi/matic-logo.png";
import multi from "../assets/images/providers/upi/multi-collateral-dai-dai-logo.png";
import shiba from "../assets/images/providers/upi/shiba-inu-shib-logo.png";
import tether from "../assets/images/providers/upi/tether-logo-png-transparent.png";
import UsdCoin from "../assets/images/providers/upi/UsdCoin.png";
import LanguageChange from "../views/components/LanguageChange";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };

  const [isLoader, setShowLoader] = useState(false);
  const { appDetails } = useSelector((state) => state.app);

  const handleExchangeLaunch = () => {
    setShowLoader(true);
    socket.emit("get:spbwicket9_lobby", {}, (res) => {
      if (res.data?.error === true) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        return false;
      }
      setShowLoader(false);
      window.open(res?.data?.data?.lobbyUrl, "_blank");
    });

    return () => {
      socket.off("get:spbwicket9_lobby");
    };
  };
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "cashier",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  // useEffect(() => {
  //   const addGoogleTranslateScript = () => {
  //     const script = document.createElement("script");
  //     script.src =
  //       "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   };

  //   const googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement(
  //       {
  //         pageLanguage: "en",
  //         includedLanguages: "en,nl",
  //         layout: "google.translate.TranslateElement.InlineLayout.SIMPLE",
  //       },
  //       "google_translate_element"
  //     );
  //   };

  //   var userLang = navigator.language || navigator.userLanguage;
  //   // alert ("The language is: " + userLang);
  //   // setcookie('googtrans', '/en/pt');
  //   document.cookie = `googtrans=/en/${userLang};max-age=604800;`;

  //   if (window.google && window.google.translate) {
  //     googleTranslateElementInit();
  //   } else {
  //     window.googleTranslateElementInit = googleTranslateElementInit;
  //     addGoogleTranslateScript();
  //   }
  // }, []);
  return (
    <footer className="footer">
      <div className="upi-provider">
        <ul>
          <li>
            <a href="#">
              <img src={BitCoin} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={dogecoinDogeLogo} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={apecoin} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Ethereum} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={bitcoinCash} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={litecoin} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={matic} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={shiba} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={multi} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={tether} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={UsdCoin} />
            </a>
          </li>
          {/* <li>
            <a href="#">
              <img src={Visa} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Tether} />
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <img src={Paytm} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={rupay} />
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <img src={neteller} />
            </a>
          </li>

          <li>
            <a href="#">
              <img src={Bitcoin} />
            </a>
          </li>

          <li>
            <a href="#">
              <img src={Skrill} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Astropay} />
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <img src={Phonepay} />
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <img src={Netbank} />
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <img src={Gpay} />
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <img src={Mastercard} />
            </a>
          </li> */}
        </ul>
      </div>
      <div className="FooterSection">
        <div className="FooterLeftPart">
          <div className="footer-links">
            <div>
              <div className="footer-logo d-none">
                {appDetails?.LOGO_URL && (
                  <img src={appDetails?.LOGO_URL || logo} />
                )}
              </div>
              <p className="d-none">{appDetails?.FOOTER_TEXT || ""}</p>
            </div>
            <div className="d-flex d-none d-md-flex betlinksSection">
              <div className="betlinks">
                <h4>Takegambles</h4>
                <ul>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>

                  <li>
                    <a href="/responsible-game">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/qtechlobby">Slot</a>
                  </li>
                </ul>
              </div>
              <div className="betlinks">
                <h4>Usage and Terms</h4>
                <ul>
                  <li>
                    <a href="/fair-play">Fair Play</a>
                  </li>
                  <li>
                    <a href="/Bettingrules">Betting Rules</a>
                  </li>
                  <li>
                    <a href="/termscondition">Terms and Conditions</a>
                  </li>
                </ul>
              </div>
              {/* <div>asdasd</div> */}
            </div>
            <div className="footerTelephone d-none d-md-flex">
              <ul>
                <li>
                  <a href="#faq">
                    <div className="footsectionPart">
                      <AiOutlineQuestionCircle />
                      <p>FAQ</p>
                    </div>
                    <img src={rightArrowSeeAll} alt="arrow" />
                  </a>
                </li>
                <li>
                  <a href={`tel://${appDetails?.PHONE}`}>
                    <div className="footsectionPart">
                      <BsTelephone />
                      {appDetails?.PHONE}
                    </div>
                    <img src={rightArrowSeeAll} alt="arrow" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="footsectionPart">
                      <AiOutlineMobile />
                      <p>Mobile App</p>
                    </div>
                    <img src={rightArrowSeeAll} alt="arrow" />
                  </a>
                </li>
              </ul>{" "}
            </div>
            <div className="">
              <div className="secureGaming">
                <ul>
                  {/* <li>
                    <img src={Noga} className="freebet" />
                  </li>
                  <li>
                    <img src={LoketKansspel} className="freebet" />
                  </li> */}
                  <li>
                    <img src={IBIA} className="freebet" />
                  </li>
                  <li>
                    <img src={cgf} className="freebet" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="FooterRightPart">
          <div className="language-option">
            {/* <div id="google_translate_element" /> */}
            <LanguageChange />
            {/* <div className="custom-google-translate-icon">Language</div> */}

            {/* <select
              className="language-select btn-outline-secondary beforelogin-language"
              value={window.localStorage.i18nextLng}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option> */}
            {/* <option value="dutch">Ductch</option> */}
            {/* </select> */}
          </div>
          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {/* <span className="notification-count">2</span> */}
                  {/* <img src={Whatsapp} alt="Whatsapp Icon" /> */}
                  <BsWhatsapp />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "#"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  {/* <img src={Telegram} alt="Telegram Icon" /> */}
                  <BsTelegram />
                </a>
              </li>
            </ul>
          </div>

          <div className="d-md-none d-block">
            <div className="app-img" onClick={handleShow}>
              <img src="./images/google-play.png" alt="Google PlayStore Logo" />
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="app-modal"
            >
              <Modal.Body closeButton>
                <div className="app-installed">
                  <div className="app-left-part">
                    <Button variant="secondary" onClick={handleClose}>
                      X
                    </Button>
                    <div className="app-body">
                      <div className="app-logo">
                        {appDetails?.LOGO_URL && (
                          <img
                            src={appDetails?.LOGO_URL || logo}
                            alt="App Logo"
                          />
                        )}
                      </div>
                      <a className="app-name">
                        <h5>Takegambles</h5>
                        <div className="app-rate">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <BsStarHalf />
                        </div>
                        <a
                          href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                          download="0TtatjwLdZwgvIkSmZxS.apk"
                        >
                          Download the app
                        </a>
                      </a>
                    </div>
                  </div>

                  <div className="app-install-btn">
                    <a
                      href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                      download="0TtatjwLdZwgvIkSmZxS.apk"
                    >
                      <button type="button" className="installBtn">
                        INSTALL
                      </button>
                    </a>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li onClick={() => handleExchangeLaunch()}>
              <a
                className={activePage === "sportsbook" ? "active" : ""}
                href="/betby"
              >
                <SportsIcon />
                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "casino/ezugi" ? "active" : ""}
                href="/casino/evolution"
              >
                <GamesIcon />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "cashier" ? "active" : ""}
                href="/cashier"
              >
                <CashierIcon />
                <span>Cashier</span>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "#"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                {/* <HeartIcon /> */}
                <ProfileIcon />
                <span>Support</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
