import { isMobile } from "react-device-detect";

import Card32new from "../../assets/images/32card.png";
export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;
export const casinoTabs = {
  LiveDealer: {
    // CoffeeWild: {
    //   href: "/casino/pp-coffee-wild",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? { src: "./images/slots/pragmaticplay/mobile/CoffeeWild.jpg", alt: "" }
    //     : { src: "./images/slots/pragmaticplay/CoffeeWild.jpg", alt: "" },
    // },

    // DownTheRails: {
    //   href: "/casino/pp-down-the-rails",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/DownTheRails.jpg",
    //         alt: "",
    //       }
    //     : { src: "./images/slots/pragmaticplay/DownTheRails.jpg", alt: "" },
    // },
    // FLoatingDragonMegaways: {
    //   href: "/casino/pp-floating-dragon-megaways",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/FLoatingDragonMegaways.jpg",
    //         alt: "",
    //       }
    //     : {
    //         src: "./images/slots/pragmaticplay/FLoatingDragonMegaways.jpg",
    //         alt: "",
    //       },
    // },
    // GreedyWOlf: {
    //   href: "/casino/pp-greedy-wolf",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? { src: "./images/slots/pragmaticplay/mobile/GreedyWOlf.jpg", alt: "" }
    //     : { src: "./images/slots/pragmaticplay/GreedyWOlf.jpg", alt: "" },
    // },
    vivoAndarBahar: {
      href: "casino/vivo-andar-bahar",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/vivoAndarBahar.jpg", alt: "" }
        : { src: "./images/dealer/vivoAndarBahar.jpg", alt: "" },
    },
    vivoTeenPatti: {
      href: "casino/vivo-teen-patti",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/vivoTeenPatti.jpg", alt: "" }
        : { src: "./images/dealer/vivoTeenPatti.jpg", alt: "" },
    },
    FootballStudio: {
      href: "casino/ezgevo-football-studio",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/FOotBallStudio.jpg", alt: "" }
        : { src: "./images/dealer/live-dealer-evolution11.jpg", alt: "" },
    },
    DreamCatcher: {
      href: "casino/ezgevo-dream-catcher",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/DreamCatcher.jpg", alt: "" }
        : { src: "./images/dealer/live-dealer-evolution21.jpg", alt: "" },
    },
    InfiniteBlackjack: {
      href: "casino/ezgevo-infinite-blackjack",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/InfiniteBlackjack.jpg", alt: "" }
        : { src: "./images/dealer/Infinite-blackjack.jpg", alt: "" },
    },
    LightningRouletee: {
      href: "casino/ezgevo-lightning-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/LightningROulette.jpg", alt: "" }
        : { src: "./images/dealer/Lightning-roulete.jpg", alt: "" },
    },

    // MagicMoneyMaze: {
    //   href: "/casino/pp-magic-money-maze",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images//slots/pragmaticplay/mobile/MagicMoneyMaze.jpg",
    //         alt: "",
    //       }
    //     : { src: "./images/slots/pragmaticplay/MagicMoneyMaze.jpg", alt: "" },
    // },
    xxxtremeRoulette: {
      href: "casino/ezgevo-xxxtreme-lightning-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/dealer/mobile/xxxtremelightningroulette.jpg",
            alt: "",
          }
        : { src: "./images/dealer/XXXtremeLightningRoulette.jpg", alt: "" },
    },
    // bigbasssplash: {
    //   href: "/casino/pp-big-bass-splash",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/bigbasssplash.jpg",
    //         alt: "",
    //       }
    //     : {
    //         src: "./images/slots/pragmaticplay/pgp_bigbasssplash.jpg",
    //         alt: "",
    //       },
    // },
    // bombbonanza: {
    //   href: "/casino/pp-bomb-bonanza",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/bombbonanza.jpg",
    //         alt: "",
    //       }
    //     : { src: "./images/slots/pragmaticplay/pgp_bombbonanza.jpg", alt: "" },
    // },
  },
  LiveCasino: {
    SpeedRoulette1: {
      href: "casino/ezg-speed-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/SpeedROulette.jpg", alt: "" }
        : { src: "./images/dealer/live-dealer-ezugi11.jpg", alt: "" },
    },
    DragonRiger1: {
      href: "casino/ezg-dragon-tiger",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/DragonTiger.jpg", alt: "" }
        : { src: "./images/dealer/dragon-tiger.jpg", alt: "" },
    },
    UltimateRoulette: {
      href: "casino/ezg-ultimate-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/UltimateRoulette.jpg", alt: "" }
        : { src: "./images/dealer/ultimate-roulette.jpg", alt: "" },
    },
    blackjack1: {
      href: "casino/ezg-blackjack-1",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/Blackjack.jpg", alt: "" }
        : { src: "./images/dealer/Blackjack.jpg", alt: "" },
    },
    FootballStudio: {
      href: "casino/ezgevo-football-studio",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/FOotBallStudio.jpg", alt: "" }
        : { src: "./images/dealer/live-dealer-evolution11.jpg", alt: "" },
    },
    DreamCatcher: {
      href: "casino/ezgevo-dream-catcher",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/DreamCatcher.jpg", alt: "" }
        : { src: "./images/dealer/live-dealer-evolution21.jpg", alt: "" },
    },
    InfiniteBlackjack: {
      href: "casino/ezgevo-infinite-blackjack",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/InfiniteBlackjack.jpg", alt: "" }
        : { src: "./images/dealer/Infinite-blackjack.jpg", alt: "" },
    },
    LightningRouletee: {
      href: "casino/ezgevo-lightning-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/LightningROulette.jpg", alt: "" }
        : { src: "./images/dealer/Lightning-roulete.jpg", alt: "" },
    },
    autoRoulette1: {
      href: "casino/ezg-auto-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/dealer/mobile/Roulette.jpg", alt: "" }
        : { src: "./images/dealer/ROulettenew.jpg", alt: "" },
    },
    CrazyTimeEvolution: {
      href: "casino/ezgevo-crazy-time",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/crazytime.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/crazytime.jpg", alt: "" },
    },
    RouletteEvolution1: {
      href: "casino/ezugi/RouletteEvolution",
      code: "1000094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/livecasino/homepage/mobile/Roulette.jpg", alt: "" }
        : { src: "./images/livecasino/homepage/Roulette.jpg", alt: "" },
    },
    LightningRouleteeEvolution: {
      href: "casino/ezgevo-lightning-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/LightningRoulette.jpg",
            alt: "",
          }
        : {
            src: "./images/livecasino/homepage/LightningRoulette.jpg",
            alt: "",
          },
    },
    xxxtremeRouletteEvolution: {
      href: "casino/ezgevo-xxxtreme-lightning-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/XxxtremeLightningRoulette.jpg",
            alt: "",
          }
        : {
            src: "./images/livecasino/homepage/XxxtremeLightningRoulette.jpg",
            alt: "",
          },
    },
    SpeedBlackjackAEvolution: {
      href: "casino/ezgevo-blackjack-a",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/SpeedBlackjack.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/SpeedBlackjack.jpg", alt: "" },
    },
    InfiniteBlackjackA: {
      href: "casino/ezgevo-infinite-blackjack",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/InfiniteBlackjack.jpg",
            alt: "",
          }
        : {
            src: "./images/livecasino/homepage/InfiniteBlackjack.jpg",
            alt: "",
          },
    },
    SpeedRouletteEvolution: {
      href: "casino/ezgevo-speed-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/Speedroulette.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/Speedroulette.jpg", alt: "" },
    },
    DreamCatcherEvolution: {
      href: "casino/ezgevo-dream-catcher",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/Dreamcatcher.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/Dreamcatcher.jpg", alt: "" },
    },
    AutoRouletteEvolution: {
      href: "casino/ezgevo-speed-auto-roulette",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/Autoroulette.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/AutorouletteNew.jpg", alt: "" },
    },
    MegaballEvolution: {
      href: "casino/ezgevo-mega-ball",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/livecasino/homepage/mobile/Megaball.jpg", alt: "" }
        : { src: "./images/livecasino/homepage/Megaball.jpg", alt: "" },
    },
    FootballStudioEvolution: {
      href: "casino/ezgevo-football-studio",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/FOotballStudio.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/FOotballStudio.jpg", alt: "" },
    },
    DragonTigerEvolution: {
      href: "casino/ezg-dragon-tiger",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/DragonTiger.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/DragonTiger.jpg", alt: "" },
    },
    BaccaratAEvolution: {
      href: "casino/ezgevo-baccarat-a",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? { src: "./images/livecasino/homepage/mobile/BaccaratA.jpg", alt: "" }
        : { src: "./images/livecasino/homepage/BaccaratA.jpg", alt: "" },
    },
    CasinoHoldemEvolution: {
      href: "casino/ezgevo-casino-holdem-evo",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/CasinoHoldem.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/CasinoHoldem.jpg", alt: "" },
    },
    UltimatetexasholdemEvolution: {
      href: "casino/ezgevo-ultimate-texas-holdem",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/Ultimatetexasholdem.jpg",
            alt: "",
          }
        : {
            src: "./images/livecasino/homepage/Ultimatetexasholdem.jpg",
            alt: "",
          },
    },
    CashorCrashEvolution: {
      href: "casino/ezgevo-cash-or-crash",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/CashOrCrash.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/CashOrCrash.jpg", alt: "" },
    },
    LightningDiceEvolution: {
      href: "casino/ezgevo-lightning-dice",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: isMobile
        ? {
            src: "./images/livecasino/homepage/mobile/LightningDice.jpg",
            alt: "",
          }
        : { src: "./images/livecasino/homepage/LightningDice.jpg", alt: "" },
    },
  },
  Blackjacktab1: {
    slide1: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      TurkceBlackjack: {
        redirectUrl: "/casino/ezugi/TurkceBlackjack",
        code: "501",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
        alt: "",
        name: "Turkce Blackjack 3",
        providerName: "Ezugi",
      },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      FreebetBlackjack: {
        redirectUrl: "/casino/ezugi/FreebetBlackjack",
        code: "1000066",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
        alt: "",
        name: "Freebet Blackjack",
        providerName: "Evolution",
      },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack1: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack1",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide2: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      ClubBlackjack: {
        redirectUrl: "/casino/ezugi/ClubBlackjack",
        code: "502",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Club-Blackjack.jpg",
        alt: "",
        name: "Club Blackjack",
        providerName: "Ezugi",
      },
      // Rumbablackjacks2: {
      //   redirectUrl: "/casino/ezugi/Rumbablackjacks2",
      //   code: "412",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 2",
      //   providerName: "Ezugi",
      // },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      // AllStarsBlackjackGold1: {
      //   redirectUrl: "/casino/ezugi/AllStarsBlackjackGold1",
      //   code: "504",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
      //   alt: "",
      //   name: "All Stars Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },

    // slide3: {
    //   Blackjack17Azure: {
    //     redirectUrl: "/casino/ezugi/Blackjack17Azure",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack17Azure.jpg",
    //     alt: "",
    //     name: "Blackjack 17 Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   GoldBlackjack: {
    //     redirectUrl: "/casino/ezugi/GoldBlackjack",
    //     code: "1",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack02.jpg",
    //     alt: "",
    //     name: "Blackjack Gold 5",
    //     providerName: "Ezugi",
    //   },
    //   Blackjack39Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack39Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack39Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 39 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack42Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack42Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack42Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 42 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   UnlimitedBlackjack: {
    //     redirectUrl: "/casino/ezugi/UnlimitedBlackjack",
    //     code: "51",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Unlimited-Blackjack.jpg",
    //     alt: "",
    //     name: "Unlimited Blackjack",
    //     providerName: "Ezugi",
    //   },

    //   BlackjackVIPO: {
    //     redirectUrl: "/casino/ezugi/BlackjackVIPO",
    //     code: "1000058",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack-VIP-O.jpg",
    //     alt: "",
    //     name: "Blackjack VIP O",
    //     providerName: "Evolution",
    //   },
    //   Blackjack11: {
    //     redirectUrl: "/casino/ezugi/Blackjack11",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack11.jpg",
    //     alt: "",
    //     name: "Blackjack 11",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack14: {
    //     redirectUrl: "/casino/ezugi/Blackjack14",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack14.jpg",
    //     alt: "",
    //     name: "Blackjack 14",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack16: {
    //     redirectUrl: "/casino/ezugi/Blackjack16",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack16.jpg",
    //     alt: "",
    //     name: "Blackjack 16",
    //     providerName: "Pragmatic Play",
    //   },
    //   OneBlackjack: {
    //     redirectUrl: "/casino/ezugi/OneBlackjack",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/OneBlackjack.jpg",
    //     alt: "",
    //     name: "One Blackjack",
    //     providerName: "Pragmatic Play",
    //   },
    //   ONEBlackjack2: {
    //     redirectUrl: "/casino/ezugi/ONEBlackjack2",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/ONEBlackjack2.jpg",
    //     alt: "",
    //     name: "One Blackjack 2",
    //     providerName: "Pragmatic Play",
    //   },
    //   SpeedBlackjack3Ruby: {
    //     redirectUrl: "/casino/ezugi/SpeedBlackjack3Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/SpeedBlackjack3Ruby.jpg",
    //     alt: "",
    //     name: "Speed Blackjack 3 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Blackjack",
  },
  roulettetab1: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
      // AutoRoulettePargmatic: {
      //   redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
      //   code: "vs20stickysymbol",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
      //   alt: "",
      //   name: "Auto Roulette Pragmatic",
      //   providerName: "Pragmatic Play",
      // },
      // Roulette1Azure: {
      //   redirectUrl: "/casino/ezugi/Roulette1Azure",
      //   code: "227",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Roulette1Azure.jpg",
      //   alt: "",
      //   name: "Roulette Azure",
      //   providerName: "Pragmatic Play",
      // },
      // RouletteLobby: {
      //   redirectUrl: "/casino/ezugi/RouletteLobby",
      //   code: "102",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/roulletewheel.jpg",
      //   alt: "",
      //   name: "Roulette Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    // slide3: {
    //   AutoRoulettePargmatic: {
    //     redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
    //     code: "225",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
    //     alt: "",
    //     name: "Auto Roulette Pragmatic",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette1Azure: {
    //     redirectUrl: "/casino/ezugi/Roulette1Azure",
    //     code: "227",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette1Azure.jpg",
    //     alt: "",
    //     name: "Roulette Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteLobby: {
    //     redirectUrl: "/casino/ezugi/RouletteLobby",
    //     code: "102",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulletewheel.jpg",
    //     alt: "",
    //     name: "Roulette Lobby",
    //     providerName: "Pragmatic Play",
    //   },
    //   PragmaticTurkishRoulette: {
    //     redirectUrl: "/casino/ezugi/PragmaticTurkishRoulette",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/pragturkish.jpg",
    //     alt: "",
    //     name: "Turkish Roulette ",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulettegerman: {
    //     redirectUrl: "/casino/ezugi/roulettegerman",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulettegerman.jpg",
    //     alt: "",
    //     name: "Roulette German",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteRussian: {
    //     redirectUrl: "/casino/ezugi/RouletteRussian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette-Russian.jpg",
    //     alt: "",
    //     name: "Roulette Russian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette3Macao: {
    //     redirectUrl: "/casino/ezugi/Roulette3Macao",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette3Macao.jpg",
    //     alt: "",
    //     name: "Roulette Macao",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette9TheClub: {
    //     redirectUrl: "/casino/ezugi/Roulette9TheClub",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette9TheClub.jpg",
    //     alt: "",
    //     name: "Roulette The Club",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulette7italian: {
    //     redirectUrl: "/casino/ezugi/roulette7italian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulette7italian.jpg",
    //     alt: "",
    //     name: "Roulette 7 Italian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette10Ruby: {
    //     redirectUrl: "/casino/ezugi/Roulette10Ruby",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette10Ruby.jpg",
    //     alt: "",
    //     name: "Roulette 10 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Roulette",
  },
  pokertab: {
    slide1: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti1: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti1",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    name: "Poker",
  },
  baccarattab1: {
    slide1: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide2: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    // slide3: {
    //   NoCommSpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/NoCommSpeedBaccarat1",
    //     code: "436",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/NoCommSpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "No Commission Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   SpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/SpeedBaccarat1",
    //     code: "566",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/SpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "Speed Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   // speedbaccarat5: {
    //   //   redirectUrl: "/casino/ezugi/speedbaccarat5",
    //   //   code: "570",
    //   //   casino: "ezugi",
    //   //   provider: "",
    //   //   homeUrl: pageUrlGenerator("/Casino"),
    //   //   imgUrl: "./images/roulette/speedbaccarat5.jpg",
    //   //   alt: "",
    //   //   name: "Speed Baccarat 5",
    //   //   providerName: "Pragmatic Play",
    //   // },
    // },
    name: "Baccarat",
  },
  TvShows: {
    slide1: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      GonzosTreasureHunt: {
        redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
        code: "1100022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
        alt: "",
        name: "Gonzo's Treasure Hunt",
        providerName: "Evolution",
      },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "TV Games",
  },
  PopularGames: {
    DreamCatcher1: {
      href: "/casino/ezugi/DreamCatcher1",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/dream-catcher.jpg", alt: "" },
    },
    CrazyTime: {
      href: "/casino/ezugi/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Crazy-time.jpg", alt: "" },
    },
    MegaBall: {
      href: "/casino/ezugi/MegaBall",
      code: "1000141",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/MegaBall1.jpg", alt: "" },
    },
    Lucky7: {
      href: "/casino/ezugi/Lucky7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Lucky-7.jpg", alt: "" },
    },
    DragonTiger1: {
      href: "/casino/ezugi/DragonTiger1",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/dragon-tiger.jpg", alt: "" },
    },
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Speed-Roulette.jpg", alt: "" },
    },
    Teenpatticard: {
      href: "/casino/ezugi/Teenpatticard",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Teenpatti-card.jpg", alt: "" },
    },
    cards32: {
      href: "/casino/ezugi/cards32",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/32-cards.jpg", alt: "" },
    },
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/AndarBahar.jpg", alt: "" },
    },
  },
  Slots: {
    // cheekyemperor: {
    //   href: "/casino/pp-cheeky-emperor",
    //   code: "1100039",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/CheekyEmperor.jpg",
    //         alt: "",
    //       }
    //     : {
    //         src: "./images/slots/pragmaticplay/pgp_cheekyemperor.jpg",
    //         alt: "",
    //       },
    // },
    // cleocatra: {
    //   href: "/casino/pp-cleocatra",
    //   code: "1100018",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/Cleocatra.jpg",
    //         alt: "",
    //       }
    //     : {
    //         src: "./images/slots/pragmaticplay/pgp_cleocatra.jpg",
    //         alt: "",
    //       },
    // },
    // cosmiccash: {
    //   href: "/casino/pp-cosmic-cash",
    //   code: "1100096",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/CosmicCash.jpg",
    //         alt: "",
    //       }
    //     : { src: "./images/slots/pragmaticplay/pgp_cosmiccash.jpg", alt: "" },
    // },
    // mahjongpanda: {
    //   href: "/casino/pp-mahjong-panda",
    //   code: "1100106",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/MahjongPanda.jpg",
    //         alt: "",
    //       }
    //     : { src: "./images/slots/pragmaticplay/pgp_mahjongpanda.jpg", alt: "" },
    // },
    // firehot5: {
    //   href: "/casino/pp-fire-hot-5",
    //   code: "1100150",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? { src: "./images/slots/pragmaticplay/mobile/FireHot5.jpg", alt: "" }
    //     : { src: "./images/slots/pragmaticplay/pgp_firehot5.jpg", alt: "" },
    // },
    // octobeerfortunes: {
    //   href: "/casino/pp-octobeer-fortunes",
    //   code: "1100031",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: isMobile
    //     ? {
    //         src: "./images/slots/pragmaticplay/mobile/OctobeerFortunes.jpg",
    //         alt: "",
    //       }
    //     : {
    //         src: "./images/slots/pragmaticplay/pgp_octobeerfortunes.jpg",
    //         alt: "",
    //       },
    // },
    // Nights1000: {
    //   href: "/casino/ezugi/Nights1000",
    //   code: "1100003",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/slots/ezugi-slots/10001Nights.jpg", alt: "" },
    // },
    // CashUltimate: {
    //   href: "/casino/ezugi/CashUltimate",
    //   code: "1100070",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/slots/Cash_Ultimate.jpg", alt: "" },
    // },
    // CashVolt: {
    //   href: "/casino/ezugi/CashVolt",
    //   code: "1100071",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/slots/ezugi-slots/Cash_Volt.jpg", alt: "" },
    // },
    // DazzleMegaways: {
    //   href: "/casino/ezugi/DazzleMegaways",
    //   code: "1100083",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: {
    //     src: "./images/slots/ezugi-slots/Dazzle_Me_Megaways.jpg",
    //     alt: "",
    //   },
    // },
    // DivinieFortuneMegaways: {
    //   href: "/casino/ezugi/DivinieFortuneMegaways",
    //   code: "1100089",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: {
    //     src: "./images/slots/ezugi-slots/Divine_Fortune_Megaways.png",
    //     alt: "",
    //   },
    // },
    // Dynamiteriches: {
    //   href: "/casino/ezugi/Dynamiteriches",
    //   code: "1100073",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/slots/ezugi-slots/FortuneHouse.png", alt: "" },
    // },
    // FortuneHouse: {
    //   href: "/casino/ezugi/FortuneHouse",
    //   code: "1100007",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/slots/ezugi-slots/777_Strike.png", alt: "" },
    // },
    // WillysChills: {
    //   href: "/casino/ezugi/WillysChills",
    //   code: "1100128",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: {
    //     src: "./images/slots/ezugi-slots/Willys_Hot_Chillies.png",
    //     alt: "",
    //   },
    // },
  },
  roulettetab: {
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-roulette.png", alt: "" },
    },
    DiamondRoulette: {
      href: "/casino/ezugi/DiamondRoulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/diamond-roulette.png", alt: "" },
    },
    NamasteRoulette: {
      href: "/casino/ezugi/NamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/mamaste-roulette.png", alt: "" },
    },
    TurkishRoulette: {
      href: "/casino/ezugi/TurkishRoulette",
      code: "5501000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/turkish-roulette.png", alt: "" },
    },
    AutoRoulette: {
      href: "/casino/ezugi/AutoRoulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
    },
    SpeedAutoRoulette: {
      href: "/casino/ezugi/SpeedAutoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-auto-roulette.png", alt: "" },
    },
    OracleRoulette2: {
      href: "/casino/ezugi/OracleRoulette2",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/oracle-roulette-2.png", alt: "" },
    },
    PortomasoRoulette1: {
      href: "/casino/ezugi/PortomasoRoulette1",
      code: "611004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/portomaso-roulette-1.jpg", alt: "" },
    },
    ItalianRoulette: {
      href: "/casino/ezugi/ItalianRoulette",
      code: "1000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/italian-roulette.jpg", alt: "" },
    },
    SpanishRoulette: {
      href: "/casino/ezugi/SpanishRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/spanish-roulette.jpg", alt: "" },
    },
    RussianRoulette: {
      href: "/casino/ezugi/RussianRoulette",
      code: "601000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/russian-roulette.jpg", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/fiesta-roulette.jpg", alt: "" },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/prestige-auto-oulette.jpg", alt: "" },
    },
    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/11.png", alt: "" },
    },
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/ezugi/Baccaratpro",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat01.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat02.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat03.png", alt: "" },
    },
    Super6baccarat: {
      href: "/casino/ezugi/Super6baccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat04.png", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat05.png", alt: "" },
    },

    Marina02baccarat: {
      href: "/casino/ezugi/Marina02baccarat",
      code: "32101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat06.png", alt: "" },
    },
    Salsabaccarat: {
      href: "/casino/ezugi/Salsabaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat07.png", alt: "" },
    },
    Baccarat: {
      href: "/casino/ezugi/Baccarat",
      code: "106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat08.png", alt: "" },
    },
    Baccaratpro02: {
      href: "/casino/ezugi/Baccaratpro02",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat09.png", alt: "" },
    },
    SpeedBaccarat: {
      href: "/casino/ezugi/SpeedBaccarat",
      code: "105",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
    Marina03baccarat: {
      href: "/casino/ezugi/Marina03baccarat",
      code: "32103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar01.png", alt: "" },
    },
    AndarBaharMarnia: {
      href: "/casino/ezugi/AndarBaharMarnia",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar02.png", alt: "" },
    },
  },
  TeenPattitab: {
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker01.png", alt: "" },
    },
    TeenPatti02: {
      href: "/casino/ezugi/TeenPatti02",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker02.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker03.png", alt: "" },
    },
    TeenPatti04: {
      href: "/casino/ezugi/TeenPatti04",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker04.png", alt: "" },
    },
    TeenPatti05: {
      href: "/casino/ezugi/TeenPatti05",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker05.png", alt: "" },
    },
    TeenPatti06: {
      href: "/casino/ezugi/TeenPatti06",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker06.jpeg", alt: "" },
    },
    TeenPatti07: {
      href: "/casino/ezugi/TeenPatti07",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker07.png", alt: "" },
    },
    TeenPatti08: {
      href: "/casino/ezugi/TeenPatti08",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker08.png", alt: "" },
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/ezugi/Blackjack",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack01.png", alt: "" },
    },
    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack02.png", alt: "" },
    },
    BlackjackPro: {
      href: "/casino/ezugi/BlackjackPro",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack03.png", alt: "" },
    },
    VIPBlackjack: {
      href: "/casino/ezugi/VIPBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack04.png", alt: "" },
    },
    BlackjackAutoSplit: {
      href: "/casino/ezugi/BlackjackAutoSplit",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack05.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack06.png", alt: "" },
    },
    LiveBlackjack: {
      href: "/casino/ezugi/LiveBlackjack",
      code: "221",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack07.png", alt: "" },
    },
    RumbaBlackjack: {
      href: "/casino/ezugi/RumbaBlackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack09.png", alt: "" },
    },
    DiamondBlackjack: {
      href: "/casino/ezugi/DiamondBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack010.png", alt: "" },
    },
    Rumba02Blackjack: {
      href: "/casino/ezugi/Rumba02Blackjack",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    VipBlackjack: {
      href: "/casino/ezugi/VipBlackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    Vip02Blackjack: {
      href: "/casino/ezugi/Vip02Blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack013.png", alt: "" },
    },
    TurkishBlackjack: {
      href: "/casino/ezugi/TurkishBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack015.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/ezugi/BlackjackLive",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    BlackjackTurkce: {
      href: "/casino/ezugi/BlackjackTurkce",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    PlatinumBlackjack: {
      href: "/casino/ezugi/PlatinumBlackjack",
      code: "5051",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack019.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/32cards01.png", alt: "" },
    },
  },
  sevenLuckytab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/Lucky01.png", alt: "" },
    },
  },
  sicbotab: {
    sicbodice01: {
      href: "/casino/ezugi/sicbodice01",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice01.png", alt: "" },
    },
    sicbodice02: {
      href: "/casino/ezugi/sicbodice02",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice02.png", alt: "" },
    },
    sicbodice03: {
      href: "/casino/ezugi/sicbodice03",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice03.png", alt: "" },
    },
    sicbodice04: {
      href: "/casino/ezugi/sicbodice04",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice04.jpeg", alt: "" },
    },
    sicbodice05: {
      href: "/casino/ezugi/sicbodice05",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice05.png", alt: "" },
    },
  },
  ezugi: {
    cardsthirty2: {
      href: "/casino/ezugi/cardsthirty2",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/32card.png", alt: "" },
    },
  },

  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      pilnko: {
        href: "/casino/spribe/pilnko",
        code: "pilnko",
        casino: "spribe",
        provider: "pilnko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "miniroulette",
        casino: "spribe",
        provider: "miniroulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hilo",
        casino: "miniroulette",
        provider: "hilo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
};
export const data = {
  Blackjacktab1: {
    slide1: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      TurkceBlackjack: {
        redirectUrl: "/casino/ezugi/TurkceBlackjack",
        code: "501",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
        alt: "",
        name: "Turkce Blackjack 3",
        providerName: "Ezugi",
      },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      FreebetBlackjack: {
        redirectUrl: "/casino/ezugi/FreebetBlackjack",
        code: "1000066",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
        alt: "",
        name: "Freebet Blackjack",
        providerName: "Evolution",
      },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },
      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack1: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack1",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide2: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      ClubBlackjack: {
        redirectUrl: "/casino/ezugi/ClubBlackjack",
        code: "502",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Club-Blackjack.jpg",
        alt: "",
        name: "Club Blackjack",
        providerName: "Ezugi",
      },
      // Rumbablackjacks2: {
      //   redirectUrl: "/casino/ezugi/Rumbablackjacks2",
      //   code: "412",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 2",
      //   providerName: "Ezugi",
      // },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      // AllStarsBlackjackGold1: {
      //   redirectUrl: "/casino/ezugi/AllStarsBlackjackGold1",
      //   code: "504",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
      //   alt: "",
      //   name: "All Stars Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      BlackjackGold3: {
        redirectUrl: "/casino/ezugi/BlackjackGold3",
        code: "203",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
        alt: "",
        name: " Blackjack Gold 3  ",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },

    // slide3: {
    //   Blackjack17Azure: {
    //     redirectUrl: "/casino/ezugi/Blackjack17Azure",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack17Azure.jpg",
    //     alt: "",
    //     name: "Blackjack 17 Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   GoldBlackjack: {
    //     redirectUrl: "/casino/ezugi/GoldBlackjack",
    //     code: "1",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack02.jpg",
    //     alt: "",
    //     name: "Blackjack Gold 5",
    //     providerName: "Ezugi",
    //   },
    //   Blackjack39Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack39Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack39Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 39 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack42Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack42Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack42Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 42 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   UnlimitedBlackjack: {
    //     redirectUrl: "/casino/ezugi/UnlimitedBlackjack",
    //     code: "51",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Unlimited-Blackjack.jpg",
    //     alt: "",
    //     name: "Unlimited Blackjack",
    //     providerName: "Ezugi",
    //   },

    //   BlackjackVIPO: {
    //     redirectUrl: "/casino/ezugi/BlackjackVIPO",
    //     code: "1000058",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack-VIP-O.jpg",
    //     alt: "",
    //     name: "Blackjack VIP O",
    //     providerName: "Evolution",
    //   },
    //   Blackjack11: {
    //     redirectUrl: "/casino/ezugi/Blackjack11",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack11.jpg",
    //     alt: "",
    //     name: "Blackjack 11",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack14: {
    //     redirectUrl: "/casino/ezugi/Blackjack14",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack14.jpg",
    //     alt: "",
    //     name: "Blackjack 14",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack16: {
    //     redirectUrl: "/casino/ezugi/Blackjack16",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack16.jpg",
    //     alt: "",
    //     name: "Blackjack 16",
    //     providerName: "Pragmatic Play",
    //   },
    //   OneBlackjack: {
    //     redirectUrl: "/casino/ezugi/OneBlackjack",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/OneBlackjack.jpg",
    //     alt: "",
    //     name: "One Blackjack",
    //     providerName: "Pragmatic Play",
    //   },
    //   ONEBlackjack2: {
    //     redirectUrl: "/casino/ezugi/ONEBlackjack2",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/ONEBlackjack2.jpg",
    //     alt: "",
    //     name: "One Blackjack 2",
    //     providerName: "Pragmatic Play",
    //   },
    //   SpeedBlackjack3Ruby: {
    //     redirectUrl: "/casino/ezugi/SpeedBlackjack3Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/SpeedBlackjack3Ruby.jpg",
    //     alt: "",
    //     name: "Speed Blackjack 3 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Blackjack",
  },
  roulettetab1: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
      // AutoRoulettePargmatic: {
      //   redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
      //   code: "vs20stickysymbol",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
      //   alt: "",
      //   name: "Auto Roulette Pragmatic",
      //   providerName: "Pragmatic Play",
      // },
      // Roulette1Azure: {
      //   redirectUrl: "/casino/ezugi/Roulette1Azure",
      //   code: "227",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Roulette1Azure.jpg",
      //   alt: "",
      //   name: "Roulette Azure",
      //   providerName: "Pragmatic Play",
      // },
      // RouletteLobby: {
      //   redirectUrl: "/casino/ezugi/RouletteLobby",
      //   code: "102",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/roulletewheel.jpg",
      //   alt: "",
      //   name: "Roulette Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    // slide3: {
    //   AutoRoulettePargmatic: {
    //     redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
    //     code: "225",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
    //     alt: "",
    //     name: "Auto Roulette Pragmatic",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette1Azure: {
    //     redirectUrl: "/casino/ezugi/Roulette1Azure",
    //     code: "227",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette1Azure.jpg",
    //     alt: "",
    //     name: "Roulette Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteLobby: {
    //     redirectUrl: "/casino/ezugi/RouletteLobby",
    //     code: "102",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulletewheel.jpg",
    //     alt: "",
    //     name: "Roulette Lobby",
    //     providerName: "Pragmatic Play",
    //   },
    //   PragmaticTurkishRoulette: {
    //     redirectUrl: "/casino/ezugi/PragmaticTurkishRoulette",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/pragturkish.jpg",
    //     alt: "",
    //     name: "Turkish Roulette ",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulettegerman: {
    //     redirectUrl: "/casino/ezugi/roulettegerman",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulettegerman.jpg",
    //     alt: "",
    //     name: "Roulette German",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteRussian: {
    //     redirectUrl: "/casino/ezugi/RouletteRussian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette-Russian.jpg",
    //     alt: "",
    //     name: "Roulette Russian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette3Macao: {
    //     redirectUrl: "/casino/ezugi/Roulette3Macao",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette3Macao.jpg",
    //     alt: "",
    //     name: "Roulette Macao",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette9TheClub: {
    //     redirectUrl: "/casino/ezugi/Roulette9TheClub",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette9TheClub.jpg",
    //     alt: "",
    //     name: "Roulette The Club",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulette7italian: {
    //     redirectUrl: "/casino/ezugi/roulette7italian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulette7italian.jpg",
    //     alt: "",
    //     name: "Roulette 7 Italian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette10Ruby: {
    //     redirectUrl: "/casino/ezugi/Roulette10Ruby",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette10Ruby.jpg",
    //     alt: "",
    //     name: "Roulette 10 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Roulette",
  },
  pokertab: {
    slide1: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti1: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti1",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    name: "Poker",
  },
  baccarattab1: {
    slide1: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide2: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    // slide3: {
    //   NoCommSpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/NoCommSpeedBaccarat1",
    //     code: "436",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/NoCommSpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "No Commission Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   SpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/SpeedBaccarat1",
    //     code: "566",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/SpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "Speed Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   // speedbaccarat5: {
    //   //   redirectUrl: "/casino/ezugi/speedbaccarat5",
    //   //   code: "570",
    //   //   casino: "ezugi",
    //   //   provider: "",
    //   //   homeUrl: pageUrlGenerator("/Casino"),
    //   //   imgUrl: "./images/roulette/speedbaccarat5.jpg",
    //   //   alt: "",
    //   //   name: "Speed Baccarat 5",
    //   //   providerName: "Pragmatic Play",
    //   // },
    // },
    name: "Baccarat",
  },
  TvShows: {
    slide1: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },
      GonzosTreasureHunt: {
        redirectUrl: "/casino/ezugi/GonzosTreasureHunt",
        code: "1100022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/GonzosTreasureHunt.jpg",
        alt: "",
        name: "Gonzo's Treasure Hunt",
        providerName: "Evolution",
      },
      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "TV Games",
  },
};
