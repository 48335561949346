import React, { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const LanguageChange = () => {
  const [cookies, setCookie] = useCookies();
  const [defaultLanguage, setDefaultLanguage] = useState("en");

  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement("script");
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
    };

    const googleTranslateElementInit = () => {
      if (cookies?.googtrans) {
        let d = cookies?.googtrans?.split("/");
        setCookie("googtrans", `/en/${d?.[2]}`, 1);
      }

      new window.google.translate.TranslateElement(
        {
          // pageLanguage: "en",
          includedLanguages:
            // "ar,en,pt-BR,bg,hr,cs,da,nl,en,fil,fr,de,hi,id,it,ja,ko,pt-PT,ru,sr,zh-CN,es,tr,uk",
            "en,nl",
          layout: "google.translate.TranslateElement.InlineLayout.SIMPLE",
        },
        "google_translate_element"
      );
    };

    var userLang = navigator.language || navigator.userLanguage;
    // alert("The language is: " + userLang);
    // setcookie('googtrans', '/en/pt');
    document.cookie = `googtrans=/en/${userLang};max-age=604800;`;

    if (window.google && window.google.translate) {
      googleTranslateElementInit();
    } else {
      window.googleTranslateElementInit = googleTranslateElementInit;
      addGoogleTranslateScript();
    }
  }, []);
  return (
    <div className="language-option">
      <div id="google_translate_element" />
      {/* {/ <div className="custom-google-translate-icon">Language</div> /} */}

      {/* <select
              className="language-select btn-outline-secondary beforelogin-language"
              value={window.localStorage.i18nextLng}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option> */}
      {/* {/ <option value="dutch">Ductch</option> /} */}
      {/* {/ </select> /} */}
    </div>
  );
};

export default LanguageChange;
