import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { axiosService } from "../../utils/axiosService";

import HeaderAfterLogin from "../../containers/HeaderAfterLogin";
import Header from "../../containers/Header";
import Footer from "../../containers/Footer";
import BeforeLoginFooter from "../../containers/BeforeLoginFooter";
import Sports from "../pages/privatePages/sports";

const NewExchangeLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const [casinoLobbyUrl, setCasinoLobbyUrl] = useState();

  useEffect(() => {
    console.log("useEffect");
    try {
      if (localStorage.getItem("token") !== null) {
        const result = axiosService.post(
          "https://jetfair.sportsinfo247.com/lobby/",
          {
            token: localStorage.getItem("token"),
            mobile: false,
          }
        );
        result
          .then((res) => {
            console.log(res);
            if (!res?.data?.error) {
              return setCasinoLobbyUrl(res.data.data.lobbyUrl);
            }
          })
          .catch((err) => {
            console.log(err);
            return;
          });
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }, []);
  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}
      <div className="container-fluid ">
        <div className="home-iframe ">
          {isAuth ? (
            <div>
              {!Boolean(casinoLobbyUrl) ? (
                <div className="custom_placeholder_parent">
                  <div className="custom_placeholder_child">
                    <Spinner animation="grow" variant="primary" size="sm" />
                    <Spinner animation="grow" variant="primary" size="sm" />
                    <Spinner animation="grow" variant="primary" size="sm" />
                  </div>
                </div>
              ) : casinoLobbyUrl === "notAllowed" ? (
                <Navigate to="/home" />
              ) : casinoLobbyUrl === "notFound" ? (
                <Navigate to="/home" />
              ) : (
                <div className="exchange_iframe">
                  <div>
                    <iframe
                      title="Exchange"
                      src={casinoLobbyUrl}
                      frameBorder="0"
                      style={{
                        width: "100%",
                        height: "100vh",
                        maxHeight: "calc(100vh - 140px)",
                        // aspectRatio: "16/9",
                      }}
                      // scrolling="no"
                      allowFullScreen={true}
                    >
                      Your browser doesn't support iFrames.
                    </iframe>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="beforelogin-iframe">
              <iframe src="https://design.powerplay247.com/auth" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewExchangeLogin;
