import React, { useState, useEffect } from "react";

const OddsCard = ({
  oddData,
  oddValues,
  oddType,
  isDetail,
  handleOddsSelect,
}) => {
  const [animateOdds, SetAnimateOdds] = useState(false);
  const oddClasses = oddType == "back" ? "info-block yes" : "info-block no";
  const oddProperty = oddType === "back" ? "availableToBack" : "availableToLay";
  const oddPrice =
    oddValues?.Price ||
    oddValues?.price ||
    oddData?.ex?.[oddProperty]?.[0]?.price ||
    "-";
  const oddSize =
    oddValues?.Size ||
    oddValues?.size ||
    oddData?.ex?.[oddProperty][0]?.size ||
    "-";
  useEffect(() => {
    SetAnimateOdds(true);
    setTimeout(() => SetAnimateOdds(false), 200);
    return () => {};
  }, [oddPrice, oddSize]);
  return (
    <>
      {isDetail ? (
        <div
          className={`${oddClasses}  ${animateOdds && "oddsChanged"}`}
          onClick={() => {
            if (oddPrice !== "-" && oddSize !== "-") {
              handleOddsSelect();
              // handleShowRightSidebar();
            }
          }}
        >
          <span>{oddPrice}</span>
          {oddSize}
        </div>
      ) : (
        <li
          onClick={() => {
            if (oddPrice !== "-" && oddSize !== "-") {
              handleOddsSelect();
              // handleShowRightSidebar();
            }
          }}
        >
          <div className={`${oddClasses}  ${animateOdds && "oddsChanged"}`}>
            <span>{oddPrice}</span>
          </div>
        </li>
      )}
    </>
  );
};

export default OddsCard;
