import React from "react";
import Slider from "react-slick";
import Slide1 from "../assets/images/VSlider/slide1.jpg";
import Slide2 from "../assets/images/VSlider/slide2.jpg";
import Slide3 from "../assets/images/VSlider/slide3.jpg";
const VerticalSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  return (
    <div className="VSlider">
      <Slider {...settings}>
        <div>
          <div className="slide_box">
            <a href="/casino/ezugi">
              <img src={Slide1} alt="First Slide" />
              <div className="overlay"></div>
            </a>
          </div>
        </div>
        <div>
          <div className="slide_box">
            <a href="/casino/ezugi">
              <img src={Slide2} alt="Second Slide" />
              <div className="overlay"></div>
            </a>
          </div>
        </div>
        <div>
          <div className="slide_box">
            <a href="/casino/ezugi">
              <img src={Slide3} alt="Thired Slide" />
              <div className="overlay"></div>
            </a>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default VerticalSlider;
