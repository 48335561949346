import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import RummyIcon from "../assets/svg/RummyIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import GameShowIcon from "../assets/svg/GameShowIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Fbicon from "../assets/images/head-icon/facebook.png";
import Twitter from "../assets/images/head-icon/twitter.png";
import Telegram from "../assets/images/head-icon/telegram.png";
import Instagram from "../assets/images/head-icon/instagram.png";
import Mobile from "../assets/images/head-icon/mobile.png";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import numberWithCommas from "../utils/numbersWithComma";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import EzugiIcon from "../assets/images/betnew1.png";
import EvolutionIcon from "../assets/images/roulette.png";
import SlotsIcon from "../assets/images/jackpot1.png";
import PromotionIcon from "../assets/images/promotenew1.png";
import HomeIcon1 from "../assets/images/homenew1.png";
import SportsIcon1 from "../assets/images/throw.png";
import CasinoIcon1 from "../assets/images/casino-roulette.png";
import { APP_CONST } from "../config/const";
import { socket } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import Loader from "../assets/images/games-tab/loader-new.gif";
import i18next, { t } from "i18next";

import ProfileIcon1 from "../assets/images/games-icon/profile.png";
import AccountStatement1 from "../assets/images/games-icon/account-statement.png";
import SignOut1 from "../assets/images/games-icon/sign-out.png";
import WithDraw from "../assets/images/games-icon/Withdraw.png";
import { RiHandCoinFill } from "react-icons/ri";

import AffilateIcon from "../assets/images/affiliate-marketing.png";
import { Container, Modal } from "react-bootstrap";

import Rupee from "../assets/images/rupee.png";
import ProfileIconNew from "../assets/images/icons/profilenew.png";
import Account from "../assets/images/icons/profilenew.png";
import Transaction from "../assets/images/icons/transaction1.png";
import Referral from "../assets/images/icons/referral.png";
import Promotion from "../assets/images/icons/promotion.png";
import Bonus1 from "../assets/images/icons/bonus1.png";
import SignOut from "../assets/images/icons/logout.png";
import MyBets from "../assets/images/icons/money.png";
import Withdraw from "../assets/images/icons/withdrawal.png";

import WalletIconnew from "../assets/images/icons/wallet.png";
import bonus from "../assets/images/bonus.png";
import referral from "../assets/images/Referral.png";
const HeaderAfterLogin = () => {
  const [dropShow, setDropShow] = useState(true);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };
  const { user, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showToggle, setShowToggle] = useState(true);
  const [isLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    setShowToggle(window.screen.width > 991.98);
    return () => {};
  }, []);

  const handleExchangeLaunch = () => {
    setShowLoader(true);
    socket.emit("get:spbwicket9_lobby", {}, (res) => {
      if (res.data?.error === true) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        return false;
      }
      setShowLoader(false);
      window.open(res?.data?.data?.lobbyUrl, "_blank");
    });

    return () => {
      socket.off("get:spbwicket9_lobby");
    };
  };
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);
  return (
    <header className="header aftrlgn">
      <div className={isLoader ? "loading_img2" : "loading_img2 d-none"}>
        <img src={Loader} alt="Loader" />
      </div>

      <div className="top-head">
        <div className="tophead-left">
          <ul>
            <li>
              <a
                className="panel"
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Fbicon} />
                <p>Facebook</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                // href={
                //   appDetails?.TWITTER != ""
                //     ? appDetails?.TWITTER
                //     : "#"
                // }
                // target={appDetails?.TWITTER != "" ? "_blank" : ""}
              >
                <img src={Twitter} />
                <p>Twitter</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} />
                <p>Instagram</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} />
                <p>Telegram Channel</p>
              </a>
            </li>

            <li>
              <a href="#" onClick={handleShow1}>
                <img src={Mobile} />
                <p>Message Us</p>
              </a>
            </li>
          </ul>
        </div>
        <div className="tophead-right d-none">
          <div className="time-date">
            <span>May 26, 2023 at 1:57:22 PM</span>
          </div>
          <div className="language">
            <div className="language-option">
              <select
                className="language-select btn-outline-secondary beforelogin-language"
                value={window.localStorage.i18nextLng}
                onChange={handleLanguageChange}
              >
                <option value="en">English</option>
                <option value="arab">عربى</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        className="app-modal"
      >
        <Modal.Body closeButton>
          <div className="app-installed">
            <div className="app-left-part">
              <Button variant="secondary" onClick={handleClose1}>
                X
              </Button>
              <div className="app-body">
                <a className="app-name">
                  <h5>Takegambles</h5>
                  <div className="app-rate">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <BsStarHalf />
                  </div>
                  <a
                    href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                    download="0TtatjwLdZwgvIkSmZxS.apk"
                  >
                    Download the app
                  </a>
                </a>
              </div>
            </div>

            <div className="app-install-btn">
              <a
                href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                download="0TtatjwLdZwgvIkSmZxS.apk"
              >
                <button type="button" className="installBtn">
                  INSTALL
                </button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-between align-items-center nav-head">
        <div className="d-flex align-items-center">
          <div className="leftbar_toggle d-xl-none">
            <Button
              variant="primary"
              onClick={() => setShowLeftbarToggle((s) => !s)}
            >
              <MenuIcon />
            </Button>
          </div>
          <div className="logo">
            <a href="/">
              {appDetails?.LOGO_URL && (
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              )}
            </a>
          </div>
          <Navbar expand="lg">
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/betby?bt-path=%2Fcricket-21">
                    Cricket
                  </Nav.Link>
                  {/* <Nav.Link href="/sportsbook">Exchange</Nav.Link> */}
                  <Nav.Link href="/betby">SportsBook</Nav.Link>

                  <Nav.Link href="/casino/evolution">Live Casino</Nav.Link>
                  {/* <Nav.Link href="/casino/vivo">Vivo</Nav.Link> */}
                  <Nav.Link href="#">Promotion</Nav.Link>
                  <Nav.Link href="/casino/spribe/plinko">Plinko</Nav.Link>
                  <Nav.Link href="/casino/spribe/aviator">Aviator</Nav.Link>
                  <Nav.Link href="/qtechlobby">Slots</Nav.Link>
                  {/* <Nav.Link href="/casino/worldcasino">Worldcasino</Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {/* <Button variant="primary menu-btn" onClick={handleShow2}>
            <MenuIcon />
          </Button> */}
        </div>
        <div className="headerRight">
          <div
            className="user-drop"
            onClick={() => {
              setDropShow(!dropShow);
            }}
          >
            <div className="user-drop-wrp">
              <div className="user-button">
                <img src={ProfileIconNew} />
              </div>
              <div className={`user-content-section`}>
                <div className={`user-content  ${dropShow && `d-none`}`}>
                  <ul>
                    <li>
                      <span>{user?.mstruserid}</span>
                      <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
                    </li>

                    <li>
                      <p className="m-0">
                        Balance
                        <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </p>
                    </li>

                    <li>
                      <div className="balance-amount">
                        <p>
                          Casino Bonus
                          <span>{numberWithCommas(wallet?.coins) || 0}</span>
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div className="user-other-option">
                    <ul>
                      <li>
                        <a href="/profile">
                          <img src={Account} />
                          Profile
                        </a>
                      </li>
                      <li>
                        <a href="/cashier">
                          {" "}
                          <img src={Transaction} />
                          Transaction
                        </a>
                      </li>
                      <li>
                        <a href="/withdraw">
                          {" "}
                          <img src={Withdraw} />
                          Withdraw
                        </a>
                      </li>
                      <li>
                        <a href="/bonus">
                          <img src={bonus} />
                          Bonus
                        </a>
                      </li>{" "}
                      <li>
                        <a href="/referral">
                          <img src={referral} />
                          Referral
                        </a>
                      </li>
                      <li>
                        <a href="/mybets">
                          <img src={MyBets} />
                          Bet History
                        </a>
                      </li>
                      {/* <li>
                        <a href="/bonus">
                          {" "}
                          <img src={Bonus1} />
                          Bonus
                        </a>
                      </li>
                      <li>
                        <a href="/referral">
                          <img src={Referral} />
                          Referral
                        </a>
                      </li>

                      <li>
                        <a href="/promotion">
                          <img src={Promotion} />
                          Promotion
                        </a>
                      </li> */}
                      <li onClick={() => dispatch(logoutUser())}>
                        <a href="#" className="signout">
                          <img src={SignOut} />
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="language-option">
            <select
              className="language-select btn-outline-secondary beforelogin-language"
              value={window.localStorage.i18nextLng}
              onChange={handleLanguageChange}
            >
              <option value="en">Eng</option>
              <option value="arab">عربى</option>
            </select>
          </div> */}
          <div className="header-balance d-none">
            <p className="m-0">
              {t("balance")}: <span>{numberWithCommas(wallet?.cash || 0)}</span>
            </p>
          </div>
          {/* <div
            className="depositIconNew d-block d-md-none "
            onClick={() => navigate("/gatewaylist")}
          >
            <RiHandCoinFill />
          </div> */}
          <div className="funds" onClick={() => navigate("/gatewaylist")}>
            <div className="d-md-none d-block">
              <img src={WalletIconnew} className="wallet" alt="walllet icon" />
            </div>
            <div className="headerRightSection">
              <Dropdown align="end" className="header-balance ">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {/* <span className="d-none d-md-block">{t("balance")}:</span> */}
                  <span className="d-none">Bal: </span>
                  {/* <img
                        src={Balance}
                        alt="balance img"
                        className="d-block d-md-none"
                      /> */}
                  <span>{numberWithCommas(wallet?.cash || 0)}</span>€
                </Dropdown.Toggle>
              </Dropdown>
              <ul>
                {/* <li>
                        <div onClick={() => setShowBetModal(true)}>
                          <div>
                            Exp:{" "}
                            <span>{numberWithCommas(exposureAmount || 0)}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <a href="#">
                          Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                        </a>
                      </li> */}
                <li className="">
                  <a href="/gatewaylist" className="deposit">
                    Deposit
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <Button variant="primary menu-btn">
                  <ProfileIcon />
                  <MenuIcon />
                </Button> */}
          <Dropdown className="headerMainDropdown d-none" placement={"end"}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {/* <img src={EnglandFlag} /> */}
              <ProfileIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">
                <Button
                  variant="primary  w-100"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  {/* <ProfileIcon /> */}
                  <img src={ProfileIcon1} />
                  <span>{t("profile")}</span>
                </Button>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <div
                  className={`user-content-section ${dropShow && `dropShow`}`}
                >
                  <div className="user-content">
                    <ul>
                      <li>
                        <span>{user?.mstruserid}</span>
                        <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
                      </li>

                      <li>
                        <p className="m-0">
                          Balance
                          <span>{numberWithCommas(wallet?.cash || 0)}</span>
                        </p>
                      </li>

                      <li>
                        <div className="balance-amount">
                          <p>
                            Casino Bonus
                            <span>{numberWithCommas(wallet?.coins) || 0}</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div className="user-other-option">
                      <ul>
                        <li>
                          <a href="/profile">
                            <img src={Account} />
                            Profile
                          </a>
                        </li>
                        <li>
                          <a href="/cashier">
                            {" "}
                            <img src={Transaction} />
                            Transaction
                          </a>
                        </li>
                        <li>
                          <a href="/withdraw">
                            {" "}
                            <img src={Withdraw} />
                            Withdraw
                          </a>
                        </li>
                        <li>
                          <a href="/mybets">
                            <img src={MyBets} />
                            My Bets
                          </a>
                        </li>
                        {/* <li>
                          <a href="/bonus">
                            {" "}
                            <img src={Bonus1} />
                            Bonus
                          </a>
                        </li>
                        <li>
                          <a href="/referral">
                            <img src={Referral} />
                            Referral
                          </a>
                        </li>

                        <li>
                          <a href="/promotion">
                            <img src={Promotion} />
                            Promotion
                          </a>
                        </li> */}
                        <li onClick={() => dispatch(logoutUser())}>
                          <a href="/" className="signout">
                            <img src={SignOut} />
                            Sign Out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Dropdown.Item>

              {/* <Dropdown.Item href="#">
                      <div></div>
                      <Button
                        variant="primary  w-100"
                        onClick={() => dispatch(logoutUser())}
                      >
                        <LogoutIcon />
                        <img src={SignOut1} />
                        <span>Sign Out</span>
                      </Button>
                    </Dropdown.Item> */}
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  {/* <LogoutIcon /> */}
                  <img src={SignOut1} />
                  {/* <span>Sign Out</span> */}
                  Sign Out
                </a>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <ProfileIcon />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <p>{user?.mobileno}</p>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-3">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                INR. {numberWithCommas(wallet?.balance || 0)}
              </div>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="outline-secondary w-100">Withdraw</Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/gatewaylist");
              }}
            >
              <Button variant="primary w-100">Deposit</Button>
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
            <li onClick={() => dispatch(logoutUser())}>
              <a href="#">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <TelegramIcon />
              </a>
            </li>

            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <span className="notification-count">2</span>
                <WhatsappIcon />
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas show={show2} onHide={handleClose2} placement={"end"}>
        <Offcanvas.Header
          closeButton="closeButton"
          closeVariant="white"
        ></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-3">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                INR. {numberWithCommas(wallet?.balance || 0)}
              </div>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="outline-secondary w-100">Withdraw</Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/gatewaylist");
              }}
            >
              <Button variant="primary w-100">Deposit</Button>
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <TelegramIcon />
              </a>
            </li>

            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <span className="notification-count">2</span>
                <WhatsappIcon />
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default HeaderAfterLogin;
