import React from "react";
import moment from "moment";
import OddsCard from "./oddsCard";
import { APP_CONST } from "../../../../../config/const";
const InPlay = ({
  sportsData,
  oddsData,
  handleEventClick,
  handleBetSlip,
  handleBetSlipContent,
}) => {
  const handleBetSlipData = (match, betType, odds) => {
    const priceValue =
      betType === APP_CONST.ODDS_TYPE.BACK
        ? odds.ex?.["availableToBack"]?.[0]
        : odds.ex?.["availableToLay"]?.[0];
    const payload = {
      match,
      betType,
      odds,
      priceValue,
    };
    handleBetSlipContent(payload);
  };

  const isInPlay = (value) => {
    const matchTime = new Date(value?.matchdate);
    const currentTime = new Date();
    if (matchTime <= currentTime) {
      return true;
    }
    return false;
  };
  return (
    <>
      <div className="game-listing">
        <div className="title">
          <span>{"In Play"}</span>
        </div>
        {sportsData &&
          sportsData.map((sport, index) => {
            return sport.values.map((value, i) => {
              return (
                <>
                  {isInPlay(value) &&
                    oddsData &&
                    oddsData?.[value.marketSlug] && (
                      <div
                        className="match-info"
                        onClick={() => handleEventClick(value)}
                      >
                        <div className="match-name">
                          <div className="d-flex align-items-center">
                            <div className="breadcrumb mb-0">
                              <div className="date_time_info">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <div className="date">
                                  {moment(value?.matchdate).format(
                                    "DD MMM, YYYY h:mm "
                                  )}
                                </div>
                              </div>
                              {value?.matchName}
                            </div>
                          </div>
                          {value.visibility === "1" && (
                            <div className="status">F</div>
                          )}
                        </div>
                        <ul className="listing">
                          {oddsData && oddsData?.[value?.marketSlug] && (
                            <>
                              {/* Team 1 */}
                              <OddsCard
                                oddData={
                                  oddsData?.[value.marketSlug].runners?.[0]
                                }
                                oddType={APP_CONST.ODDS_TYPE.BACK}
                                handleOddsSelect={() => {
                                  handleBetSlip(true);
                                  handleBetSlipData(
                                    value,
                                    APP_CONST.ODDS_TYPE.BACK,
                                    oddsData?.[value.marketSlug].runners?.[0]
                                  );
                                }}
                              />
                              <OddsCard
                                oddData={
                                  oddsData?.[value.marketSlug].runners[0]
                                }
                                oddType={APP_CONST.ODDS_TYPE.LAY}
                                handleOddsSelect={() => {
                                  handleBetSlip(true);
                                  handleBetSlipData(
                                    value,
                                    APP_CONST.ODDS_TYPE.LAY,
                                    oddsData?.[value.marketSlug].runners[0]
                                  );
                                }}
                              />
                              {/* The Draw */}
                              <OddsCard
                                oddData={
                                  oddsData?.[value.marketSlug].runners?.[2]
                                }
                                oddType={APP_CONST.ODDS_TYPE.BACK}
                                handleOddsSelect={() => {
                                  handleBetSlip(true);
                                  handleBetSlipData(
                                    value,
                                    APP_CONST.ODDS_TYPE.BACK,
                                    oddsData?.[value.marketSlug].runners?.[2]
                                  );
                                }}
                              />
                              <OddsCard
                                oddData={
                                  oddsData?.[value.marketSlug].runners?.[2]
                                }
                                oddType={APP_CONST.ODDS_TYPE.LAY}
                                handleOddsSelect={() => {
                                  handleBetSlip(true);
                                  handleBetSlipData(
                                    value,
                                    APP_CONST.ODDS_TYPE.LAY,
                                    oddsData?.[value.marketSlug].runners?.[2]
                                  );
                                }}
                              />
                              {/* Team 2 */}
                              <OddsCard
                                oddData={
                                  oddsData?.[value.marketSlug].runners?.[1]
                                }
                                oddType={APP_CONST.ODDS_TYPE.BACK}
                                handleOddsSelect={() => {
                                  handleBetSlip(true);
                                  handleBetSlipData(
                                    value,
                                    APP_CONST.ODDS_TYPE.BACK,
                                    oddsData?.[value.marketSlug].runners?.[1]
                                  );
                                }}
                              />
                              <OddsCard
                                oddData={
                                  oddsData?.[value.marketSlug].runners?.[1]
                                }
                                oddType={APP_CONST.ODDS_TYPE.LAY}
                                handleOddsSelect={() => {
                                  handleBetSlip(true);
                                  handleBetSlipData(
                                    value,
                                    APP_CONST.ODDS_TYPE.LAY,
                                    oddsData?.[value.marketSlug].runners?.[1]
                                  );
                                }}
                              />
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                </>
              );
            });
          })}
      </div>
    </>
  );
};
export default InPlay;
