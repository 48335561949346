import React from "react";
import HeartIcon from "../../assets/svg/HeartIcon";

export default function CasinoCard({ data }) {
  return (
    <a
      href={data.href || "#"}
      className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"
    >
      <div className=" rounded position-relative">
        <img src={data?.cover?.src} alt={data?.cover?.alt || ""} />
      </div>
    </a>
  );
}
