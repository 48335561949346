import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import Slider from "react-slick";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { sportsSocket } from "../../../../utils/socketUtil.js";
import { setSportsData, setOddsData } from "../../../../redux/sports/actions";
import { useSelector, useDispatch } from "react-redux";
import LeftBarSports from "../../../components/LeftBarSports.js";
import RightBarCasino from "../../../components/RightBarCasino.js";
import SportsItem from "./children";
import InPlay from "./children/inplay";
import { showbetSlip } from "../../../../redux/sports/actions";
import BetSlip from "../../../components/betSlip";
import { betSlipContent } from "../../../../redux/sports/actions";
import BackIcon from "../../../../assets/svg/BackIcon";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";

const Sports = ({ isAuth }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("2");
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  // useEffect(() => {
  //   sportsSocket.on("markets", (data) => {
  //     console.log("data", data);
  //     dispatch(setSportsData(data.markets));
  //   });
  //   sportsSocket.on("marketodds", (data) => {
  //     console.log("data", data);
  //     console.log("data", data);
  //     dispatch(setOddsData(data.marketOdds));
  //   });
  //   return () => {
  //     sportsSocket.off();
  //   };
  // }, []);

  useEffect(() => {
    if (location?.state?.selectedTab) {
      console.log("location?.state?.selectedTab", location?.state?.selectedTab);
      setActiveKey(location.state.selectedTab);
    }
  }, [location]);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const handleBetSlip = (value) => {
    //dispatch(showbetSlip(value));
  };

  const handleBetSlipContent = (data) => {
    // dispatch(betSlipContent(data));
  };

  const settings = {
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];
  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}
      <main className="main d-flex">
        <LeftBarSports />

        <div className="middleContentSec">
          <div className="sports_page_banner m-2">
            <Slider {...settings}>
              <div className="position-relative">
                <img src="./images/cricket.jpg" alt="First Slide" />
              </div>
              <div className="position-relative">
                <img src="./images/Soccer.jpg" alt="Second Slide" />
              </div>
              <div className="position-relative">
                <img src="./images/Tennis.jpg" alt="Thired Slide" />
              </div>
            </Slider>
          </div>

          <div className="container">
            <div className="sports_page custom-tabs">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="2"
                activeKey={activeKey}
              >
                <Nav variant="pills" className="top_nav">
                  {tabs.map((item, index) => {
                    return (
                      <Nav.Item
                        key={index}
                        onClick={() => setActiveKey(item.eventKey)}
                      >
                        <Nav.Link eventKey={item.eventKey}>
                          {item.name}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <InPlay
                      sportsData={sportsData}
                      oddsData={oddsData}
                      handleEventClick={handleEventClick}
                      handleBetSlip={handleBetSlip}
                      handleBetSlipContent={handleBetSlipContent}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <SportsItem
                      sportsData={
                        sportsData &&
                        sportsData.find(
                          ({ sportname }) => sportname === "Cricket"
                        )
                      }
                      oddsData={oddsData}
                      handleEventClick={handleEventClick}
                      handleBetSlip={handleBetSlip}
                      handleBetSlipContent={handleBetSlipContent}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <SportsItem
                      sportsData={
                        sportsData &&
                        sportsData.find(
                          ({ sportname }) => sportname === "Soccer"
                        )
                      }
                      oddsData={oddsData}
                      handleEventClick={handleEventClick}
                      handleBetSlip={handleBetSlip}
                      handleBetSlipContent={handleBetSlipContent}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <SportsItem
                      sportsData={
                        sportsData &&
                        sportsData.find(
                          ({ sportname }) => sportname === "Tennis"
                        )
                      }
                      oddsData={oddsData}
                      handleEventClick={handleEventClick}
                      handleBetSlip={handleBetSlip}
                      handleBetSlipContent={handleBetSlipContent}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
              {/* <Tabs defaultActiveKey={activeKey}>
                <Tab
                  eventKey="1"
                  title="In Play"
                  onClick={() => setActiveKey("1")}
                >
                  <SportsItem
                    sportsData={
                      sportsData &&
                      sportsData.find(({ sportname }) => sportname === "Soccer")
                    }
                    oddsData={oddsData}
                    handleEventClick={handleEventClick}
                  />
                </Tab>
                <Tab
                  eventKey="2"
                  title="Cricket"
                  onClick={() => setActiveKey("2")}
                >
                  <div className="banner mb-3">
                    <img src="./images/GS-01.png" />
                  </div>
                  <SportsItem
                    sportsData={
                      sportsData &&
                      sportsData.find(({ sportname }) => sportname === "Cricket")
                    }
                    oddsData={oddsData}
                    handleEventClick={handleEventClick}
                  />
                </Tab>
                <Tab
                  eventKey="3"
                  title="Soccer"
                  onClick={() => setActiveKey("3")}
                >
                  <SportsItem
                    sportsData={
                      sportsData &&
                      sportsData.find(({ sportname }) => sportname === "Tennis")
                    }
                    oddsData={oddsData}
                    handleEventClick={handleEventClick}
                  />
                </Tab>
                <Tab
                  eventKey="4"
                  title="Tennis"
                  onClick={() => setActiveKey("4")}
                >
                  <SportsItem
                    sportsData={
                      sportsData &&
                      sportsData.find(({ sportname }) => sportname === "Soccer")
                    }
                    oddsData={oddsData}
                    handleEventClick={handleEventClick}
                  />
                </Tab>
              </Tabs> */}
            </div>
          </div>

          {/* <BeforeLoginFooter /> */}
        </div>

        <RightBarCasino />
      </main>

      {/* {showBetSlip && (
        <BetSlip
          handleClose={() => {
            handleBetSlip(false);
          }}
        />
      )} */}
      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
};

export default Sports;
