import React, { useEffect } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";
import { useSelector } from "react-redux";
import Slider from "react-slick";

import { slotsData } from "../../../../lib/data/slots.data";
import MainSlider from "../../../../containers/MainSlider";
import { Button, Form } from "react-bootstrap";
import Search from "../../../../assets/svg/SearchIcon";
import { getBanners } from "../../../../redux/app/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Header from "../../../../containers/Header";
const Slots = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { banners } = useSelector((state) => state.app);
  const [show, setShow] = useState(true);
  const settings = {
    dots: false,
    fade: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBanners());
  }, []);

  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <main className="main GamesSection">
        <div className="slots-section">
          <div className="games-section">
            <div className="game-heading">
              <span>Red Tiger</span>
            </div>
            <div className="allimages">
              <ul>
                {Object.values(slotsData.slotsPart1)
                  .slice(0, 10)
                  .map((item) => {
                    return (
                      <li className="game-section">
                        <a href={isAuth ? item.redirectUrl : "/sign-in"}>
                          <img src={item.imgUrl} />
                          <div className="casino_overlay">
                            <img src={PlayBtn} />
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="game-heading mt-5">
              <span>Pragmatic Play</span>
            </div>
            <div className="allimages">
              <ul>
                {Object.values(slotsData.slotsPart2).map((item) => {
                  return (
                    <li className="game-section">
                      <a href={isAuth ? item.redirectUrl : "/sign-in"}>
                        <img src={item.imgUrl} />
                        {/* {item.name} */}
                        <div className="casino_overlay">
                          <img src={PlayBtn} />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="game-heading mt-5">
              <span>Netent</span>
            </div>
            <div className="allimages">
              <ul>
                {Object.values(slotsData.slotsPart3).map((item) => {
                  return (
                    <li className="game-section">
                      <a href={isAuth ? item.redirectUrl : "/sign-in"}>
                        <img src={item.imgUrl} />
                        {/* {item.name} */}
                        <div className="casino_overlay">
                          <img src={PlayBtn} />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="game-heading mt-5">
              <span>Onlyplay</span>
            </div>
            <div className="allimages">
              <ul>
                {Object.values(slotsData.slotsPart4).map((item) => {
                  return (
                    <li className="game-section">
                      <a href={isAuth ? item.redirectUrl : "/sign-in"}>
                        <img src={item.imgUrl} />
                        {/* {item.name} */}
                        <div className="casino_overlay">
                          <img src={PlayBtn} />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="game-heading mt-5">
              <span>One Touch</span>
            </div>
            <div className="allimages">
              <ul>
                {Object.values(slotsData.slotsPart5).map((item) => {
                  return (
                    <li className="game-section">
                      <a href={isAuth ? item.redirectUrl : "/sign-in"}>
                        <img src={item.imgUrl} />
                        {/* {item.name} */}
                        <div className="casino_overlay">
                          <img src={PlayBtn} />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Slots;
