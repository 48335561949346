import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/games-icon/home-icon.png";
import CasinoIcon from "./../../assets/images/games-icon/casino-icon.png";
import InplayIcon from "./../../assets/images/games-icon/inplay-icon.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "./../../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "./../../assets/images/games-icon/tennis-icon.png";
import GolfIcon from "./../../assets/images/games-icon/golf-icon.png";
import RugbyIcon from "./../../assets/images/games-icon/rugby-icon.png";
import BoxingIcon from "./../../assets/images/games-icon/boxing-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import EsportsIcon from "./../../assets/images/games-icon/esports-icon.png";
import VolleyballIcon from "./../../assets/images/games-icon/volleyball-icon.png";
import CyclingIcon from "./../../assets/images/games-icon/cycling-icon.png";
import SnookerIcon from "./../../assets/images/games-icon/snooker-icon.png";
import BaseballIcon from "./../../assets/images/games-icon/baseball-icon.png";
import NetballIcon from "./../../assets/images/games-icon/netball-icon.png";
import BasketballIcon from "./../../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "./../../assets/images/games-icon/icehockey-icon.png";
import HandballIcon from "./../../assets/images/games-icon/handball-icon.png";
import DartsIcon from "./../../assets/images/games-icon/darts-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/politics-icon.png";
import Aviator from "./../../assets/images/icons/aviator.png";
import Plinko from "./../../assets/images/icons/plinko.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            {/* <h6>Quick Links</h6> */}
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" className="homepage" />
                  <span>HomePage</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate(isAuth ? "/betby" : "bet-by", {
                      state: { selectedTab: "1" },
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>SPORTSBOOK</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate(
                      isAuth
                        ? "/betby?bt-path=%2Fcricket-21"
                        : "/bet-by?bt-path=%2Fcricket-21",
                      {
                        state: { selectedTab: "2" },
                      }
                    );
                  }}
                >
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                  <div className="sport-event-count badge">
                    {
                      sportsData?.find(
                        ({ sportname }) => sportname === "Cricket"
                      )?.values?.length
                    }
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/betby");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>Live Betting</span>
                </div>
              </li>
              {/* <li>
                <div
                  onClick={() => {
                    navigate("/casino/worldcasino");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>Worldcasino</span>
                </div>
              </li> */}
              <li>
                <div
                  onClick={() => {
                    navigate("/qtechlobby");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>Slots</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/evolution");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>Live Casino</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/spribe/aviator");
                  }}
                >
                  <img
                    src={Aviator}
                    alt="Casino Icon"
                    className="aviator"
                    style={{ width: "50%" }}
                  />
                  {/* <span>Live Casino</span> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/spribe/plinko");
                  }}
                >
                  <img src={Plinko} alt="Casino Icon" className="aviator" />
                  {/* <span>Live Casino</span> */}
                </div>
              </li>
            </ul>
          </li>

          {/* <li className="games_link">
            <h6>Sports</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/betby", { state: { selectedTab: "3" } });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                  <div className="sport-event-count badge">
                    {" "}
                    {
                      sportsData?.find(
                        ({ sportname }) => sportname === "Soccer"
                      )?.values?.length
                    }
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/betby", { state: { selectedTab: "4" } });
                  }}
                >
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                  <div className="sport-event-count badge">
                    {" "}
                    {
                      sportsData?.find(
                        ({ sportname }) => sportname === "Tennis"
                      )?.values?.length
                    }
                  </div>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={GolfIcon} alt="Golf Icon" />
                  <span>Golf</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={RugbyIcon} alt="Rugby Icon" />
                  <span>Rugby</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={BoxingIcon} alt="Boxing Icon" />
                  <span>Boxing</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={horseracingIcon} alt="horseracing Icon" />
                  <span>horseracing</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={EsportsIcon} alt="Esports Icon" />
                  <span>Esports</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={VolleyballIcon} alt="Volleyball Icon" />
                  <span>Volleyball</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={CyclingIcon} alt="Cycling Icon" />
                  <span>Cycling</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={SnookerIcon} alt="Snooker Icon" />
                  <span>Snooker</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={BaseballIcon} alt="Baseball Icon" />
                  <span>Baseball</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={NetballIcon} alt="Netball Icon" />
                  <span>Netball</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={BasketballIcon} alt="Basketball Icon" />
                  <span>Basketball</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={IceHockeyIcon} alt="Ice Hockey Icon" />
                  <span>Ice Hockey</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={HandballIcon} alt="Handball Icon" />
                  <span>Handball</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={DartsIcon} alt="Darts Icon" />
                  <span>Darts</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={GreyhoundIcon} alt="Greyhound Racing Icon" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div href="/betby">
                  <img src={PoliticsIcon} alt="Politics Icon" />
                  <span>Politics</span>
                </div>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
