import React from "react";
import VerticalSlider from "../../containers/VerticalSlider";

import CasinoGame1 from "./../../assets/images/sidebar-games/casino-game1.jpg";
import CasinoGame2 from "./../../assets/images/sidebar-games/casino-game2.jpg";
import CasinoGame3 from "./../../assets/images/sidebar-games/casino-game3.jpg";
import CasinoGame4 from "./../../assets/images/sidebar-games/casino-game4.jpg";
import CasinoGame5 from "./../../assets/images/sidebar-games/casino-game5.jpg";
import CasinoGame6 from "./../../assets/images/sidebar-games/casino-game6.jpg";
import CasinoGame7 from "./../../assets/images/sidebar-games/casino-game7.jpg";
import CasinoGame8 from "./../../assets/images/sidebar-games/casino-game8.jpg";
import CasinoGame9 from "./../../assets/images/sidebar-games/casino-game9.jpg";
import CasinoGame10 from "./../../assets/images/sidebar-games/casino-game10.jpg";
import CasinoGame11 from "./../../assets/images/sidebar-games/casino-game11.jpg";
import CasinoGame12 from "./../../assets/images/sidebar-games/casino-game12.jpg";

const RightBarCasino = () => {
  return (
    <>
      <div className="rightbarSec">
        <h6>LIVE CASINO GAMES</h6>
        <VerticalSlider />

        <div className="casino_games">
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame1} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Baccarat Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame2} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Game Shows Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame3} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Andar Bahar Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame4} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Sic bo Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame5} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Roulette Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame6} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Black Jack Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame7} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Poker Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame8} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">First Person Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame9} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Roulette Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame10} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Hindi Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame11} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">Lucky 7 Lobby</div>
            </div>
          </a>
          <a href="/casino/ezugi" className="">
            <img src={CasinoGame12} alt="Casino Game" />
            <div className="overlay">
              <div className="game_type">32 Cards Lobby</div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default RightBarCasino;
