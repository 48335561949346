import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import Card32new from "../../../../assets/images/32card.png";
import AndarBaharEzugi from "../../../../assets/images/andar-bahar11.png";
import AutoRoulette from "../../../../assets/images/auto-roulette.png";
import AutoSpeedRoulette from "../../../../assets/images/auto-speed-roulette.png";
import BaccaratN from "../../../../assets/images/bacarrat11.png";
import Beton3P from "../../../../assets/images/beton3patti.png";
import BlackjackDiamond from "../../../../assets/images/blackjack-diamond.png";
import CasinoHold from "../../../../assets/images/casino-holdem.png";
import CricketWar from "../../../../assets/images/cricket-war.png";
import DragonTigerN from "../../../../assets/images/dragon-tiger-new.png";
import FortuneB from "../../../../assets/images/fortune-bacarrat.png";
import Lucky7N from "../../../../assets/images/lucky7.png";
import NamasteRoulette from "../../../../assets/images/namasterroulette.png";
import SicBoN from "../../../../assets/images/sicbonew.png";
import speedCricket from "../../../../assets/images/speed-cricket.png";
import SpeedRoulette from "../../../../assets/images/speed-rouletten.png";
import TeenP3Card from "../../../../assets/images/teenpatti3card.png";
import UltimateAB from "../../../../assets/images/ultimate-andar-bahar.png";
import { casinoTabs, EzugiGames } from "../../../../lib/data";
import CasinoCard from "../../../components/CasinoCard";

const EzugiPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="sectionTitle">Ezugi</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="casino-images-section">
            {/* {Array.isArray(Object.values(casinoTabs?.ezugi)) &&
              Object.values(casinoTabs?.ezugi)
                .slice(0, 16)
                .map((game, idx) => (
                  <CasinoCard key={"casinogame" + idx} data={game} />
                ))} */}

            <a href="/casino/ezugi/card32" className="casino-images">
              <img src={Card32new} />
              <p>32 Cards</p>
            </a>
            <a href="/casino/ezugi/AndarBaharEzugi" className="casino-images">
              <img src={AndarBaharEzugi} />
              <p>Andar Bahar</p>
            </a>
            <a href="/casino/ezugi/AutoRouleteEzugi" className="casino-images">
              <img src={AutoRoulette} />
              <p>Auto Roulette </p>
            </a>
            <a
              href="/casino/ezugi/AutoSpeedRouleteEzugi"
              className="casino-images"
            >
              <img src={AutoSpeedRoulette} />
              <p>Auto Speed Roulette </p>
            </a>
            <a href="/casino/ezugi/BaccaratEzugi" className="casino-images">
              <img src={BaccaratN} />
              <p>Baccarat </p>
            </a>
            <a href="/casino/ezugi/BetonEzugi" className="casino-images">
              <img src={Beton3P} />
              <p>Bet on TeenPatti</p>
            </a>
            <a href="/casino/ezugi/BlackDiamondEzugi" className="casino-images">
              <img src={BlackjackDiamond} />
              <p>Blackjack Diamond VIP</p>
            </a>
            <a href="/casino/ezugi/casinoholdEzugi" className="casino-images">
              <img src={CasinoHold} />
              <p>Casino Hold'em</p>
            </a>
            <a href="/casino/ezugi/cricketEzugi" className="casino-images">
              <img src={CricketWar} />
              <p>Cricket War</p>
            </a>
            <a href="/casino/ezugi/DragonEzugi" className="casino-images">
              <img src={DragonTigerN} />
              <p>Dragon Tiger</p>
            </a>
            <a
              href="/casino/ezugi/FortunrBaccaratEzugi"
              className="casino-images"
            >
              <img src={FortuneB} />
              <p> Fortune Baccarat</p>
            </a>
            <a href="/casino/ezugi/lucky7Ezugi" className="casino-images">
              <img src={Lucky7N} />
              <p>Lucky 7</p>
            </a>
            <a
              href="/casino/ezugi/namasteRouletteEzugi"
              className="casino-images"
            >
              <img src={NamasteRoulette} />
              <p>Namaste Roulette</p>
            </a>
            <a href="/casino/ezugi/SicboEzugi" className="casino-images">
              <img src={SicBoN} />
              <p>SicBo</p>
            </a>
            <a href="/casino/ezugi/speedCricketEzugi" className="casino-images">
              <img src={speedCricket} />
              <p>Speed Cricket Baccarat</p>
            </a>
            <a
              href="/casino/ezugi/speedrouletteEzugi"
              className="casino-images"
            >
              <img src={SpeedRoulette} />
              <p>Speed Roulette</p>
            </a>
            <a href="/casino/ezugi/teenpatti3Ezugi" className="casino-images">
              <img src={TeenP3Card} />
              <p>Teen Patti 3 Cards</p>
            </a>
            <a
              href="/casino/ezugi/ultimateAndarEzugi"
              className="casino-images"
            >
              <img src={UltimateAB} />
              <p>Ultimate Andar Bahar</p>
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EzugiPage;
