import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";
import Whatsapp from "../../../../assets/images//Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import { useSelector } from "react-redux";

const BeforeContact = () => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <div>
      <div>
        <Header />
      </div>
      <main class="main page-content">
        <div class="container">
          <h3 class="page-heading">Contact Us</h3>
          <div class="contact-content d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              {/* <span className="notification-count">2</span> */}
              <img src={Whatsapp} alt="Whatsapp Icon" />
            </a>
            <a
              href={
                appDetails?.TELEGRAM != ""
                  ? appDetails?.TELEGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="Telegram Icon" />
            </a>
          </div>
        </div>
      </main>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default BeforeContact;
